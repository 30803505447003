import React from 'react';
import styled from 'styled-components';
import { media } from '../../style';

import Caret from '../../assets/svg/arrow-round.svg'

const Anchor = styled.a`
  text-decoration: none;
  color: ${({ featured, theme }) =>
    featured ? theme.colors.darkBlue : 'currentColor'};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme, featured }) =>
    theme.fontWeight[featured ? 'medium' : 'medium']};
  ${({ featured }) => featured && `margin: 4rem 0 0; display: inline-block;`}
  width: fit-content;
  position: relative;

  &:hover {
    &::after {
      transform: ${({ featured }) => featured && 'translateX(25px)'};
    }
  }

  &::after {
    ${({ featured, icon }) =>
      featured &&
      `
      content: '';
      width: 20px;
      height: 14px;
      transition: transform .35s ease;
      background-image: url(${icon});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      top: 5px;
      left: calc(100% + 10px);
    `}
  }
`;

const LangAnchor = styled(Anchor)`
  margin-right: 10px;

  span {
    font-size: inherit;
  }
`

const StyledMenuAnchor = styled(Anchor)`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ isDropDown, featured, theme }) =>
    theme.fontWeight[isDropDown && featured ? 'medium' : 'medium']};
  cursor: pointer;
  display: inline-block;
  margin: 0 1.4rem;

  &:hover {
    &::after {
      background-color: ${({ theme, active }) =>
        active ? null : theme.colors.lightGray};
      transition: background-color 0.25s ease-in;
    }
  }

  &::after {
    background-color: ${({ active, theme }) =>
      theme.colors[active ? 'teal' : 'transparent']};
    bottom: -10px;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  ${media.lessThan('navigationBreak')`
    margin: .5em;
    &::after {
      content: none;
    }
  `}
`;

const MenuAnchorWithCaret = styled.div`
  svg {
    display: none;
  }
  ${ media.lessThan('navigationBreak')`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      transition: transform 0.15s ease;
      transform: scale(0.8) rotate(${ ({ active }) => active ? 270 : 90 }deg);
      display: inline-block;
    }
  `}
`

const StyledDropDownAnchor = styled(Anchor)`
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: ${({ featured, theme }) =>
    theme.fontWeight[featured ? 'medium' : 'regular']};
  & img {
    margin-right: 1.5rem;
    width: 4rem;
    height: 4rem;
  }
  & span {
    position: relative;
  }
  &:hover {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    & span::after {
      content: '';
      position: absolute;
      width: 100%;
      border-bottom: solid 2px ${({ theme }) => theme.colors.teal};
      left: 0;
      bottom: -7px;
    }
  }
  ${media.lessThan('navigationBreak')`
    font-weight: ${({ theme, featured }) =>
      theme.fontWeight[featured ? 'medium' : 'regular']} !important;
  `}
`;

const DropDownAnchor = props => {
  const { children, icon, href, target, itemProp, active, featured } = props
  return (
    <StyledDropDownAnchor href={ href } target={ target } itemProp={ itemProp } active={ active } featured={ featured }>
      { icon ? <img src={ icon } alt={ children } /> : null }
      <span>{ children }</span>
    </StyledDropDownAnchor>
  )
}

const MenuAnchor = props => {
  const { isDropDown } = props
  if (isDropDown) {
    const { children, active } = props
    return (
      <MenuAnchorWithCaret active={ active }>
        <StyledMenuAnchor { ...props }>
          { children }
        </StyledMenuAnchor>
        <Caret/>
      </MenuAnchorWithCaret>
    )
  }
  return <StyledMenuAnchor { ...props } />
}

const Link = props => {
  const {
    path, children, target, langLink, menuLink, dropLink, active, itemProp, rel, featured, icon,
    isDropDown, className
  } = props
  const anchorLinkProps = { href: path, target, itemProp, rel, featured, icon };

  return (
    <>
    { menuLink && <MenuAnchor
      href={ path }
      target={ target }
      itemProp={ itemProp }
      active={ active }
      isDropDown={ isDropDown }
      featured={ featured }
      >{ children }</MenuAnchor>
    }
    { dropLink && <DropDownAnchor
      href={ path }
      target={ target }
      icon={ icon }
      featured={ featured }
      >{ children }</DropDownAnchor>
    }
    { langLink && <LangAnchor
      href={ path }
      target={ target }
      itemProp={ itemProp }
      >{ children }</LangAnchor> }
    { !menuLink && !langLink && !dropLink && <Anchor { ...anchorLinkProps } className={ className }>{ children }</Anchor> }
    </>
  )
}

export default Link;
