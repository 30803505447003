import React from 'react';
import styled from 'styled-components';
import { media } from '../../style';

const BurgerWrap = styled.div`
	position: relative;
	cursor: pointer;
	width: 3rem;
	height: 3rem;
	
	${ media.greaterThan('navigationBreak')`
		display: none;
	`}
`

const BurgerLine = styled.span`
	position: absolute;
	display: block;
	width: 30px;
	height: 2px;
	border-radius: 2px;
	background-color: ${ ({ theme }) => theme.colors.darkGray };
	top: 1.4rem;
  right: 0;
  margin-top: ${ ({ index }) => (index === 1 ? 0 : (index === 0 ? -1 : 1)) * 0.8 }rem;
  transition: transform .15s ease, opacity .15s ease;

	&:last-child {
		width: 20px;
	}

	${ media.lessThan('navigationBreak')`
    ${ ({ opened, index }) => opened ? `
      transform-origin: center;
      ${(
        index === 0 ? 'transform: rotate(45deg); margin-top: 0;' : 
        index === 1 ? 'opacity: 0;' : 'transform: rotate(-45deg); margin-top: 0; width: 30px !important;'
      )}
    ` : '' }
  `}
`

const BurgerIcon = ({ onClick, opened }) => (
	<BurgerWrap onClick={ onClick }>
		{ [0, 1, 2].map(i => <BurgerLine key={ i } index={ i } opened={ opened } />) }
	</BurgerWrap>
);

export default BurgerIcon;
