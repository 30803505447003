import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../style';
import { Form, FormattedText } from '../Item/ModularContent';
import PlusIcon from '../../assets/svg/plus.svg';
import { Subtitle } from './';

const DELAY = 40;
const TRANSITION = 150;

const Wrapper = styled.div`
  opacity: ${({ open }) => (open ? 1 : 0)};
  z-index: 99999;
  transition: opacity ${TRANSITION}ms ease;
  position: fixed;
  background-color: rgba(52, 52, 61, 0.6);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  transform: none;
  text-align: left;
  margin: 0 !important;
`;

const Container = styled.div`
  position: relative;
  max-width: 650px;
  margin: 15vh auto 3rem;
  padding: 6rem 6.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0 7px 21px 12px rgba(52, 52, 61, 0.6),
    0 7px 13px 5px rgba(83, 83, 96, 0.1);
  ${media.lessThan('tablet')`
    margin: 15vh 1rem;
    max-width: 95vw;
    padding: 4rem 1.5rem 2rem;
  `}
`;

const StyledCross = styled.div`
  position: absolute;
  padding: 10px;
  height: 35px;
  width: 35px;
  top: 10px;
  right: 5px;
  cursor: pointer;
  svg {
    transform: rotate(45deg) scale(1.5);
    path {
      fill: ${({ theme }) => theme.colors.gray};
    }
  }
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: ${({ theme }) => theme.lineHeight.double};
  max-width: 560px;
  margin: 1rem auto 5rem;
`;

const Status = styled.a`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  background-color: ${({ theme }) => theme.colors.teal};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  margin-bottom: 3rem;
  cursor: pointer;
`;

const CrossIcon = (props) => (
  <StyledCross {...props}>
    <PlusIcon />
  </StyledCross>
);

const Dialog = ({ onClose, content }) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);
  const [toggle, setToggle] = useState(false);

  const closeDialog = () => {
    typeof open !== 'undefined' && setOpen(false);
    setTimeout(
      () => onClose && typeof onClose === 'function' && onClose(),
      TRANSITION
    );
  };
  const handleClick = (e) => {
    return (
      containerRef &&
      containerRef.current &&
      !containerRef.current.contains(e.target) &&
      closeDialog()
    );
  };

  useEffect(() => {
    if (document) {
      document.body.style.overflow = 'hidden';
    }
    if (document) {
      document.addEventListener('mousedown', handleClick);
    }
    setTimeout(() => typeof open !== 'undefined' && setOpen(true), DELAY);
    return () => {
      if (document) {
        document.body.style.overflow = '';
      }
      if (document) {
        document.removeEventListener('mousedown', handleClick);
      }
    };
  }, []);

  return (
    <Wrapper open={open}>
      <Container ref={containerRef}>
        {content.map((field, i) => {
          const { forms, formattedText, faqs } = field;
          return (
            <Fragment key={i}>
              {formattedText && <FormattedText>{formattedText}</FormattedText>}
              {faqs &&
                faqs.map((faq, i) => (
                  <div key={i}>
                    <Subtitle>{faq.title}</Subtitle>
                    {faq.toggleSwitch === true && toggle === true ? (
                      <Status
                        id="tagManagerActivate"
                        onClick={() => setToggle(false)}
                      >
                        Activar Cookies
                      </Status>
                    ) : faq.toggleSwitch === true && toggle === false ? (
                      <Status
                        onClick={() => setToggle(true)}
                        style={{ backgroundColor: '#df4b32' }}
                        id="tagManagerDeactivate"
                      >
                        Desactivar Cookies
                      </Status>
                    ) : (
                      <Status>Activas Siempre</Status>
                    )}
                    <Text>{faq.content}</Text>
                  </div>
                ))}
              {forms &&
                forms.map(
                  (
                    {
                      title,
                      actionLink,
                      actionType,
                      zapier,
                      redirectLink,
                      formModularContent,
                      direction,
                    },
                    key
                  ) => {
                    const formProps = {
                      key,
                      title,
                      actionLink,
                      actionType,
                      zapier,
                      redirectLink,
                      fields: formModularContent,
                      direction,
                    };
                    return <Form {...formProps} />;
                  }
                )}
            </Fragment>
          );
        })}
        <CrossIcon onClick={closeDialog} />
      </Container>
    </Wrapper>
  );
};

export default Dialog;
