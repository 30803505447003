import React, { useState } from 'react';
import styled from 'styled-components';
import CookieSVG from '../../assets/svg/cookie.svg';
import CancelSVG from '../../assets/svg/cancel.svg';
import { Link, CallToAction } from '../ui';

const COOKIE_NAME = 'cookies_notification_cookiebox';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  box-shadow: ${({ theme }) => theme.shadow.box};
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  padding: 1rem;
  width: 70%;
  max-width: 340px;
  z-index: 999;
  display: flex;
`;

const IconWrapper = styled.div`
  width: 25%;
  position: relative;

  & svg {
    width: 100%;
    position: absolute;
    top: -5px;
    left: 0;
  }
`;

const CtaWrapper = styled.div`
  display: flex;
`;

const CancelWrapper = styled.div`
  cursor: pointer;
  width: 15%;

  & svg {
    width: 100%;
  }
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  padding: 0 1rem;

  > a {
    font-size: inherit;
  }
`;

const Span = styled.span`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.mediumGray};
  color: ${({ theme }) => theme.colors.darkGray};

  &:hover {
    text-decoration-color: ${({ theme }) => theme.colors.darkGray};
    color: ${({ theme }) => theme.colors.black};
  }
`;

const CookieBox = ({ cookieBox }) => {
  if (typeof window !== 'undefined') {
		const { text, link, cookieCta, modifyCookies } = cookieBox;
		const ctaProps = cookieCta && cookieCta;
		const modifyCookiesProps = modifyCookies && modifyCookies;
		const [showCookieBox, setShowCookieBox] = useState(window.localStorage.getItem(COOKIE_NAME))
		const handleClick = () => {
			window.localStorage.setItem(COOKIE_NAME, '1');
			setShowCookieBox(true)
		}

    return showCookieBox ? null : (
      <Wrapper>
        <IconWrapper>
          <CookieSVG />
        </IconWrapper>
        <div>
          <Text>
            {text}
            {link && (
              <Link path={link.url}>
                <Span>{link.text} </Span>
              </Link>
            )}
          </Text>
          {modifyCookies && <CallToAction {...modifyCookiesProps} />}
          {cookieCta && (
            <CtaWrapper onClick={handleClick}>
              <CallToAction {...ctaProps} />
            </CtaWrapper>
          )}
        </div>
        <CancelWrapper onClick={handleClick}>
          <CancelSVG />
        </CancelWrapper>
      </Wrapper>
    );
  } else {
    return null;
  }
};

export default CookieBox;
