import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import styled from 'styled-components';
import { media } from '../../style';
import { Title, Subtitle, Description, Image, Container } from '../ui';
import Item from '../Item';
import isIncluded from '../../helpers/isIncluded';
import BlogSection from './BlogSection';

const Wrap = styled.section`
  background-color: ${({ bgColor }) => bgColor && bgColor};
  background-image: ${({ bgImage }) => bgImage && `url(${bgImage})`};
  background-position: ${({ bgPosition }) =>
    bgPosition ? bgPosition : 'center'};
  background-size: cover;
  background-repeat: no-repeat;
  text-align: ${({ textAlign }) => textAlign};
  padding: ${({ padding }) => (padding ? '2rem 0' : '0')};
  ${media.greaterThan('tablet')`
  padding: ${({ padding }) => (padding ? '5rem 0' : '0')};
	`}
  ${({ isLandingPage, bgImage }) =>
    isLandingPage &&
    bgImage &&
    `
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `};
`;

const gridColumns = (childrens) =>
  childrens === 0
    ? `repeat(1, 1fr)`
    : childrens <= 6
    ? `repeat(${childrens}, 1fr)`
    : 'repeat(4, 1fr)';

const gridStyle = (childrens) => `
  display: grid;
  grid-template-columns: ${childrens > 2 ? 'repeat(2, 1fr)' : ''};
  justify-content: center;
`;

const gridTablet = (childrens) => `
  grid-template-columns: ${gridColumns(childrens)};
  grid-auto-rows: auto;
  grid-gap: 4rem;
`;

const flexStyle = () => `
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  > * {
    flex-basis: 50%;
  }
`;

const flexTablet = () => `
  justify-content: center;
  > * {
    flex-basis: 25%;
  }
`;

const ItemsWrap = styled.div`
  ${({ grid, flex, childrens }) => `
    ${grid ? gridStyle(childrens) : flex ? flexStyle() : ''};
  `};
  ${({ withOverflow }) => withOverflow && 'overflow-x: hidden'};

  ${media.greaterThan('tablet')`
    ${({ grid, flex, childrens }) =>
      `${grid ? gridTablet(childrens) : flex ? flexTablet() : ''}`}
    align-items: ${({ isTeamPage }) => (isTeamPage ? 'flex-start' : 'center')};
    ${({ isTeamPage, childrens }) =>
      isTeamPage && childrens > 4
        ? 'grid-template-columns: repeat(3, 1fr);'
        : ''}
    grid-gap: 4rem;
  `}

  ${media.greaterThan('desktop')`
    ${({ isTeamPage, childrens }) =>
      isTeamPage && childrens > 4
        ? 'grid-template-columns: repeat(4, 1fr);'
        : ''}
  `}
`;

const isPricingCard = ([items]) => {
  if (items) {
    const { content } = items;
    if (content[0]) {
      return content[0].pricingCard ? true : false;
    }
  }
};

const Section = (props) => {
  const {
    items,
    title,
    headerImage,
    sectionTitle,
    sectionSubtitle,
    text,
    sectionTitleColor,
    sectionSubtitleColor,
    textColor,
    textAlign,
    padding,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    locale,
    slug,
    blogFeed,
    itemsWithContainer,
    onClick,
    grid,
    flex,
  } = props;
  const wrapProps = {
    padding,
    bgColor: backgroundColor && backgroundColor.colorhex.hex,
    bgImage: backgroundImage && backgroundImage.url,
    bgPosition: backgroundPosition && backgroundPosition,
    isLandingPage: isIncluded(title, 'landing'),
    textAlign,
  };
  const itemsWrapProps = {
    childrens: items.length,
    isTeamPage: isIncluded(title, 'team'),
    withOverflow: isPricingCard(items),
    grid,
    flex,
  };
  const titleProps = {
    textAlign,
    color: sectionTitleColor && sectionTitleColor.colorhex.hex,
  };
  const subtitleProps = {
    textAlign,
    color: sectionSubtitleColor && sectionSubtitleColor.colorhex.hex,
  };
  const descriptionProps = {
    textAlign,
    color: textColor && textColor.colorhex.hex,
    sectionDescription: true,
  };
  const haveSectionContent =
    sectionTitle || sectionSubtitle || text ? true : false;
  const imageProps = headerImage && { image: headerImage, small: true };
  const isBlogSection = isIncluded(title, 'blogfeed');
  const isRegister = isIncluded(title, 'register');

  const [isInView, setIsInView] = useState(false);

  const renderItems = () => (
    <ItemsWrap {...itemsWrapProps}>
      {items &&
        items.map((item, i) => {
          const itemProps = {
            ...item,
            locale,
            slug,
            textAlign,
            onClick,
            isRegister,
          };
          return <Item key={i} {...itemProps} />;
        })}
    </ItemsWrap>
  );

  const handleInViewChange = (inView) => setIsInView(inView);

  return (
    <Wrap {...wrapProps}>
      <InView
        as="div"
        rootMargin="50px"
        triggerOnce={true}
        onChange={handleInViewChange}
      >
        {isInView && haveSectionContent && (
          <>
            <Container>
              {headerImage && <Image {...imageProps} />}
              {sectionTitle && <Title {...titleProps}>{sectionTitle}</Title>}
              {sectionSubtitle && (
                <Subtitle {...subtitleProps}>{sectionSubtitle}</Subtitle>
              )}
              {text && <Description {...descriptionProps}>{text}</Description>}
            </Container>
            {isBlogSection && locale === 'es' && (
              <BlogSection blogFeed={blogFeed} />
            )}
          </>
        )}
        {isInView && itemsWithContainer && (
          <Container>{isInView && renderItems()}</Container>
        )}
        {isInView && !itemsWithContainer && renderItems()}
      </InView>
    </Wrap>
  );
};

export default Section;
