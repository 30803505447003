import React from 'react';
import styled from 'styled-components';
import { media } from '../../../style';

const Wrapper = styled.div`
  & * {
    margin-bottom: 1rem;
  }
  & strong {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
  p {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme, color }) => (color ? color : theme.colors.darkGray)};
    line-height: ${({ theme }) => theme.lineHeight.medium};

    ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.l};
  `}
  }
`;

const FormattedText = ({ children }) => (
  <Wrapper>
    <div dangerouslySetInnerHTML={{ __html: children }} />
  </Wrapper>
);

export default FormattedText;
