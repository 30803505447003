import React from 'react';
import Helmet from 'react-helmet';

const showSeoMetaTags = (tags) =>
  tags &&
  tags.map((tag, index) => {
    const { tagName, content, attributes } = tag;

    return tagName === 'meta' ? (
      attributes.property ? (
        <meta
          key={index}
          property={attributes.property}
          content={attributes.content}
        />
      ) : (
        <meta key={index} name={attributes.name} content={attributes.content} />
      )
    ) : null;
  });

const showFaviconTags = (favicon) =>
  favicon.tags &&
  favicon.tags.map(({ attributes: { rel, sizes, href } }, index) =>
    rel ? (
      <link key={index} rel={rel} type="image/png" href={href} sizes={sizes} />
    ) : null
  );

const showLangMeta = (locale, originalId, alternates) => {
  const filteredAlternates = alternates.filter(
    (page) => page.originalId === originalId && page.locale !== locale
  );

  return (
    filteredAlternates &&
    filteredAlternates.map((page, index) => (
      <link
        key={index}
        rel="alternate"
        href={getFullUrl(page.slug, page.locale)}
        hrefLang={page.locale}
      />
    ))
  );
};

const getFullUrl = (slug, locale) =>
  `https://getquipu.com${locale !== 'es' || slug !== '/' ? `/${locale}` : ''}${
    slug === '/' ? '' : `/${slug}`
  }`;

const SEO = (props) => {
  const {
    seoMetaTags,
    faviconMeta,
    locale,
    slug,
    originalId,
    alternates,
    title,
    metaIndexFollow,
  } = props;

  return (
    <Helmet
      defer={false}
      title={seoMetaTags && seoMetaTags.tags[0].content}
      defaultTitle={title}
    >
      <html lang={locale} />
      {seoMetaTags && showSeoMetaTags(seoMetaTags.tags)}
      {faviconMeta && showFaviconTags(faviconMeta)}
      <link rel="alternate" href={getFullUrl(slug, locale)} hrefLang={locale} />
      <link rel="canonical" href={getFullUrl(slug, locale)} />
      {metaIndexFollow !== 'no-meta' && (
        <meta name="robots" content={metaIndexFollow.replace('-', ', ')} />
      )}
      {showLangMeta(locale, originalId, alternates)}
    </Helmet>
  );
};

export default SEO;
