import React from 'react';

const TeuReview = props => {
  console.log(props);

  return (
    <div>I am the TEU review</div>
  );
};

export default TeuReview;
