import styled from 'styled-components';
import { media } from '../../style';

const Container = styled.div`
  margin: 0 auto;
  max-width: 1340px;
  padding: 0 1.5rem;

  ${ media.greaterThan('mobile')`
    padding: ${ ({ theme }) => theme.sizing.defaultPadding };
  `}
`

export default Container;
