import React from 'react';
import styled from 'styled-components';
import { media } from '../../style'

const StyledDescription = styled.p`
  text-align: center;
  font-size: ${ ({ theme }) => theme.fontSize.sm };
  font-weight: ${ ({ theme }) => theme.fontWeight.regular };
  color: ${ ({ theme, color }) => color ? color : theme.colors.darkGray };
  line-height: ${ ({ theme }) => theme.lineHeight.medium };
  margin-left: ${ ({ textAlign }) => textAlign === 'center' && 'auto' };
  margin-right: ${ ({ textAlign }) => textAlign === 'center' && 'auto' };
  margin-bottom: 5rem;

  ${ media.greaterThan('tablet')`
    font-size: ${ ({ theme }) => theme.fontSize.l };
    text-align: ${ ({ sectionDescription, textAlign }) => sectionDescription ? 'center' : textAlign };
    display: block;
  `}
`

const Description = props => {
  const { children, textAlign, color, sectionDescription } = props
  const descriptionProps = { textAlign, color, sectionDescription }
  return (
    <StyledDescription { ...descriptionProps } >{ children }</StyledDescription>
  )
}

export default Description;
