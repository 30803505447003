import React from 'react';
import styled from 'styled-components';
import CancelIcon from '../../assets/svg/cancel.svg';

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 8%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    top: 12%;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-self: end;
  padding: 4rem 3rem 0rem 0rem;
  @media only screen and (max-width: 768px) {
    padding: 6rem 3rem 0rem 0rem;
  }
`;

const IFrame = styled.iframe`
  width: 80%;
  height: auto;
  flex-grow: 2;
  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`;

const Video = (props) => {
  const { url, title, width, height, onClick } = props;
  const vimeoID = url.substring(url.lastIndexOf('/') + 1);
  const iframeProps = {
    src: `https://player.vimeo.com/video/${vimeoID}?autoplay=1`,
    title,
    width,
    height,
    frameBorder: 0,
    allowFullScreen: 1,
    allow:
      'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
  };
  return (
    <Wrapper onClick={ onClick }>
      <IconWrapper onClick={ onClick }>
        <CancelIcon />
      </IconWrapper>
      <IFrame { ...iframeProps } />
    </Wrapper>
  );
};

export default Video;
