import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../../style';

const AdblockWrapper = styled.div`
  background: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AdblockContentWrapper = styled.div`
  box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  max-width: 400px;
  background: #fff;
  height: auto;
  width: 100%;
`;

const AdblockContent = styled.div`
  padding: 30px 50px;
`;

const Center = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.l};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 3rem;
  margin-top: 5rem;

  ${media.greaterThan('tablet')`
		font-size: ${({ theme }) => theme.fontSize.ll};
		text-align: left;
		margin-top: 0;
	`}
`;

const AdblockButton = styled.div`
  line-height: 1em;
  border-radius: 25px;
  border: none;
  padding: 1.5rem 6rem;
  width: 100%;
  background: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.teal};
  box-shadow: ${({ theme }) => theme.shadow.cta};
  transition: 0.2s;
  border: 2px solid transparent;
  margin: 1rem 0;
  cursor: pointer;
  text-align: center;
`;

const Link = styled.a`
  text-decoration: none;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
`;

const BookkeeperAreaBanner = () => {
  const [bookkeeperAreaStatus, setBookkeeperAreaStatus] = useState(null);
  useEffect(() => {
    fetch(`/api/bookkeeper_area/validate`, {
      credentials: 'same-origin',
    })
      .then((response) => response.json())
      .then((resultData) => {
        setBookkeeperAreaStatus(resultData.status);
      });
  }, []);

  switch (bookkeeperAreaStatus) {
    case 'allowed':
      return null;
      break;
    case ('not_allowed', 'not_logged_in'):
      const isBrowser = () => typeof window !== 'undefined';
      const preLoginUrl = isBrowser() ? window.location.pathname : '';
      const logInLink = `/es/entrar?pre_login_url=${preLoginUrl}`;

      return (
        <>
          <AdblockWrapper>
            <AdblockContentWrapper>
              <AdblockContent>
                <Center>
                  <Heading>Para acceder debes estar logueado</Heading>
                </Center>
                <p>
                  Tienes que tener acceso a la cuenta de Asesoría para entrar en
                  la Area Privada de Quipu. <br />
                  Ponte en contacto con: support@getquipu.com
                </p>
                <AdblockButton>
                  <Link href={logInLink}>Log in</Link>
                </AdblockButton>
                <AdblockButton backgroundColor={'#0372B9'}>
                  <Link href="https://asesorias.typeform.com/to/mgjf3jQ2">
                    No soy cliente
                  </Link>
                </AdblockButton>
              </AdblockContent>
            </AdblockContentWrapper>
          </AdblockWrapper>
        </>
      );
      break;
    case null:
      return null;
      break;
  }
};

export default BookkeeperAreaBanner;
