import React from 'react'
import styled from 'styled-components'
import { media } from '../../../style'
import { Link } from '../../ui'

const Wrapper = styled.div`
  background-color: ${ ({ theme }) => theme.colors.white };
  box-shadow: ${ ({ theme }) => theme.shadow.card };
  border-radius: 4px;
  padding: 2.5rem 3rem 2rem;
  width: 100%;
  flex-shrink: 0;
  margin: 0 .5rem;
  text-align: left;

  ${ media.greaterThan('desktop')`
    position: relative;
    top: 3rem;
    left: 3rem;
    padding-left: 5rem;
  `}

  &:nth-child(2n) {
    top: 6rem;
  }
`

const Job = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.l};
  color: ${({ theme }) => theme.colors.black};
  margin-left: 0.3rem;

  ${media.greaterThan('desktop')`
    margin-left: 0;
  `}
`;

const LogoWrap = styled.div`
  width: 100px;
`

const Logo = styled.img`
  width: 100%;
`

const Author = styled.p`
  text-align: right;
  margin-top: 0.8rem;
  margin-left: 0.2rem;
  font-size: ${({ theme }) => theme.fontSize.s};
  color: ${({ theme }) => theme.colors.gray};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Text = styled.h4`
  text-align: left;
  margin-left: .2rem;
  margin-top: 1.5rem;
  line-height: ${ ({ theme }) => theme.lineHeight.medium };
`

const Testimony = ({ author, review, jobPosition, profileImage, url }) => {
  return (
    <Wrapper>
      { profileImage && (
        <LogoWrap>
          <Logo src={ profileImage.url } />
        </LogoWrap>
      )}
      <Job>
        <Link path={ url } target='_blank' rel='nofollow'>{ jobPosition }</Link>
      </Job>
      <Text>{ review }</Text>
      <Author>{ author }</Author>
    </Wrapper>
  )
} 

export default Testimony