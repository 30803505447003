import React from 'react';
import styled from 'styled-components';
import { media } from '../../style';
import { Link } from './';

const Button = styled.div`
  background-color: ${({ theme, secondary }) =>
    theme.colors[secondary ? 'white' : 'green']};
  color: ${({ theme, secondary }) =>
    theme.colors[secondary ? 'darkGreen' : 'white']};
  border-radius: 4px;
  font-family: 'IBM Plex Sans';
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  cursor: pointer;
  height: 40px;
  border: 2px solid
    ${({ theme, secondary }) => theme.colors[secondary ? 'white' : 'green']};
  font-size: 0.875rem;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5em 1.25em;
  line-height: 1.65em;
  text-decoration: none !important;
  vertical-align: text-top;
  text-align: center;
  text-transform: uppercase;
  width: ${({ block }) => (block ? '90%' : 'inherit')};
  max-width: ${({ block }) => !block && '270px'};
  margin-top: ${({ noMarginTop }) => (noMarginTop ? '0' : '2rem')};

  ${media.greaterThan('tablet')`
    max-width: 270px;
  `}

  &:hover {
    background-color: ${({ theme, secondary }) =>
      theme.colors[secondary ? 'paleTeal' : 'darkGreen']};
    border-color: ${({ theme }) => theme.colors.darkGreen};
  }
`;

const LinkButton = ({ path, secondary, block, children, noMarginTop }) => (
  <>
    <Link path={path}>
      <Button secondary={secondary} block={block} noMarginTop={noMarginTop}>
        {children}
      </Button>
    </Link>
  </>
);

export default LinkButton;
