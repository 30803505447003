import React, { useState } from 'react';
import styled from 'styled-components';
import { Dialog, Loader } from './';
import { media } from '../../style';

export const whatColor = (theme, style, outline, loading) => {
  if (loading) {
    return theme.colors.gray;
  }
  let bgColor = theme.colors.white;

  if (!outline) {
    switch (style) {
      case 'product':
        bgColor = theme.colors.teal;
        break;

      case 'service':
        bgColor = theme.colors.blue;
        break;

      case 'bookkeeper':
        bgColor = theme.colors.darkTeal;
        break;

      case 'unstyled':
        bgColor = theme.colors.white;
        break;
    }
  }

  return bgColor;
};

export const whatHover = (theme, style, outline, loading) => {
  if (loading) {
    return theme.colors.gray;
  }

  let hoverColor = theme.colors.white;

  if (outline) {
    switch (style) {
      case 'product':
        hoverColor = '#F7F7F7';
        break;

      case 'service':
        hoverColor = '#F7F7F7';
        break;

      case 'bookkeeper':
        hoverColor = '#F7F7F7';
        break;

      case 'unstyled':
        hoverColor = '#F7F7F7';
        break;
    }
  } else {
    switch (style) {
      case 'product':
        hoverColor = '#00916A';
        break;

      case 'service':
        hoverColor = '#0063A1';
        break;

      case 'bookkeeper':
        hoverColor = '#007073';
        break;

      case 'unstyled':
        hoverColor = '#F7F7F7';
        break;
    }
  }

  return hoverColor;
};

const Base = styled.a`
  display: inline-block;
  cursor: pointer;
  padding: ${({ outline, buttonStyle }) =>
    outline && buttonStyle === 'unstyled' ? '0' : '1.3rem 3rem'};
  margin: ${({ outline, buttonStyle }) =>
    outline && buttonStyle === 'unstyled' && '0'};
  text-decoration: none;
  font-weight: ${({ theme, outline, buttonStyle }) =>
    outline && buttonStyle === 'unstyled'
      ? theme.fontWeight.medium
      : theme.fontWeight.medium};
  font-size: ${({ theme, outline, buttonStyle }) =>
    outline && buttonStyle === 'unstyled'
      ? theme.fontSize.s
      : theme.fontSize.sm};
`;

const Link = styled(Base)`
  display: ${({ hideOnDesktop }) => (hideOnDesktop ? 'none' : 'inline-block')};
  color: ${({ theme }) => theme.colors.teal};
  font-size: ${({ theme }) => theme.fontSize.sm};
  display: inline-block;
  cursor: pointer;
  padding: ${({ large }) => large && '1.3rem 6rem'};
  ${media.lessThan('navigationBreak')`
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'inline-block')};
  `}
`;

const Icon = styled.img`
  margin-right: 1rem;
  width: 2.4rem;
`;

const StyledButton = styled(Base)`
  display: ${({ hideOnDesktop }) => (hideOnDesktop ? 'none' : 'inline-block')};
  background-color: ${({ theme, outline, buttonStyle, loading }) =>
    whatColor(theme, buttonStyle, outline, loading)};
  :hover {
    background-color: ${({ theme, outline, buttonStyle, loading }) =>
      whatHover(theme, buttonStyle, outline, loading)};
  }
  color: ${({ theme, outline, loading, buttonStyle }) =>
    loading
      ? 'transparent'
      : theme.colors[
          !outline
            ? 'white'
            : outline && buttonStyle === 'unstyled'
            ? theme.colors.darkGray
            : 'teal'
        ]};
  box-shadow: ${({ theme, outline, buttonStyle }) =>
    outline && buttonStyle === 'unstyled' ? 'none' : theme.shadow.cta};
  border-radius: 25px;
  padding: ${({ large, outline, buttonStyle }) =>
    large
      ? '1.5rem 6rem'
      : outline && buttonStyle === 'unstyled'
      ? '0 1rem'
      : ''};
  ${({ theme, outline, buttonStyle }) =>
    `border: 2px solid ${
      outline && buttonStyle === 'unstyled'
        ? 'transparent'
        : outline
        ? theme.colors.teal
        : 'transparent'
    }`};
  ${({ block }) => (block ? 'width: 100%;' : '')}
  height: ${({ outline, buttonStyle }) =>
    outline && buttonStyle === 'unstyled' ? '0' : '51px'};
  cursor: ${({ loading }) => (loading ? 'default' : 'pointer')} !important;
  position: relative;
  text-align: center;
  text-decoration: ${({ outline, buttonStyle }) =>
    outline && buttonStyle === 'unstyled' ? 'underline' : 'none'};
  ${media.lessThan('navigationBreak')`
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'inline-block')};
  `}
`;

export const Button = (props) => {
  const { loading, children } = props;
  return (
    <StyledButton {...props}>
      {loading ? <Loader width={26} height={26} /> : null}
      {children}
    </StyledButton>
  );
};

const CallToAction = (props) => {
  const {
    link,
    path,
    large,
    style,
    outline,
    text,
    block,
    icon,
    onClick: onClickP,
    dialog,
    hideOnDesktop,
    hideOnMobile,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const onClick = dialog ? () => setOpenDialog(true) : !path ? onClickP : null;
  const buttonProps = {
    href: path && !dialog ? path : null,
    buttonStyle: style,
    large,
    outline,
    block,
    onClick,
    hideOnDesktop,
    hideOnMobile,
  };
  const linkProps = { href: path, large, hideOnDesktop, hideOnMobile };
  return (
    <>
      {link ? (
        <Link {...linkProps}>{text}</Link>
      ) : (
        <Button {...buttonProps}>
          {icon && <Icon src={icon.url} />}
          <span>{text}</span>
        </Button>
      )}
      {openDialog ? (
        <Dialog {...dialog} onClose={() => setOpenDialog(false)}>
          THE DIALOG
        </Dialog>
      ) : null}
    </>
  );
};

export default CallToAction;
