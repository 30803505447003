import React, { useState } from 'react'
import styled from 'styled-components'
import { media } from '../../style';
import { Link } from './index'
import Arrow from '../../assets/svg/arrow.svg'

const Wrapper = styled.div`
  overflow: visible;
`
const Container = styled.div`
  position: absolute;
  padding-top: 30px;
  visibility: ${ ({ show }) => show ? 'visible' : 'hidden' };
  height: ${ ({ show }) => show ? 'auto' : 0 };
  opacity: ${ ({ show }) => show ? 1 : 0 };
  transition: opacity 0.2s ease, visibility 0.2s ease;
  ${ media.lessThan('navigationBreak')`
    padding-top: 0;
    opacity: 1;
    transition: max-height 0.15s ease-in-out;
    position: relative;
  `}
`
const DropDownLinks = styled.div`
  background-color: ${ ({ theme }) => theme.colors.white };
  width: 640px;
  height: auto;
  border-radius: 4px;
  border: solid 1px ${ ({ theme }) => theme.colors.lightGray };
  box-shadow: 2px 2px 6px 2px rgba(83, 83, 96, 0.3);
  ${ media.lessThan('navigationBreak')`
    box-shadow: none;
    border: 0;
    width: 100%;
  `}
`
const LinksWrap = styled.div`
  padding: 3.2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  & > *:nth-last-child(n+3){
    margin-bottom: 2rem;
  }
  ${ media.lessThan('navigationBreak')`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    & > *{
      margin-bottom: 2rem;
      padding: 0.5rem 0;
    }
    & > *:last-child{
      margin-bottom: 0;
    }
  `}
`
const Pointer = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 60px;
  margin-top: 1px;
  box-sizing: border-box;
  border: 10px solid;
  border-color: transparent transparent ${ ({ theme }) => `${ theme.colors.white } ${ theme.colors.white }` };
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -2px 2px 3px 0px rgba(83,83,96,0.15);
`
const StyledFooterLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 22px;
  line-height: 0.9em;
  color: ${ ({ theme }) => theme.colors.darkGreen };
  background-color: ${ ({ theme }) => theme.colors.paleTeal };
  font-weight: ${ ({ theme }) => theme.fontWeight.medium };
  & > span{
    border-bottom: 1px solid ${ ({ theme }) => theme.colors.darkGreen };
  }
  & svg{
    margin-left: 10px;
  }
  & path{
    fill: ${ ({ theme }) => theme.colors.darkGreen };
  }
  ${ media.lessThan('navigationBreak')`
    background-color: transparent;
  ` }
`

const ActionLink = ({ href, children, linkType, activeLink, showLinks, actions, isDesktop }) => {
  const linkTypeProp = {
    langLink : linkType === 'langLink',
    menuLink : linkType === 'menuLink'
  }
  const linkProps = {
    ...linkTypeProp,
    path : isDesktop ? href : null,
    active : isDesktop ? activeLink : showLinks,
    isDropDown : true,
    featured: !isDesktop
  }
  return(
    <div { ...actions }>
      <Link { ...linkProps }>
        { children }
      </Link>
    </div>
  )
}

const FooterLink = ({ text, url }) => (
  <StyledFooterLink path={ url }>
    <span>{ text }</span>
    <Arrow/>
  </StyledFooterLink>
)

const Links = ({ links, show, footerLink, isDesktop }) => (
  <Container show={ show }>
    { isDesktop ? <Pointer/> : null }
    <DropDownLinks>
      <LinksWrap>
        { links.map(({ text, target, icon, url }, i) => {
          const { url : iconUrl } = icon
          const path = url || (target ? `/${ target.locale }/${ target.urlSlug }` : '')
          const linkIcon = isDesktop ? iconUrl : null
          return (
            <Link key={ i } dropLink path={ path } icon={ linkIcon }>
              { text }
            </Link>
          )
        })}
      </LinksWrap>
      { footerLink && <FooterLink { ...footerLink }/> }
    </DropDownLinks>
  </Container>
)

const DropDown = props => {
  const { href, children, links, active : activeLink, footerLink, linkType = 'menuLink', isDesktop = false } = props
  const [ showLinks, setShowLinks ] = useState(false)
  const actions = isDesktop ? {
    onMouseEnter : () => setShowLinks(true),
    onMouseLeave : () => setShowLinks(false),
  } : {
    onClick : () => setShowLinks(!showLinks)
  }
  const actionLinkProps = {
    href,
    showLinks,
    activeLink,
    linkType,
    isDesktop,
    featured : !isDesktop && showLinks
  }
  return (
    <Wrapper { ...actions }>
      <ActionLink { ...actionLinkProps }>
        { children }
      </ActionLink>
      { links ? <Links links={ links } show={ showLinks } footerLink={ footerLink } isDesktop={ isDesktop }/> : null }
    </Wrapper>
  )
}

export default DropDown
