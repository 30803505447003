import React from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import { BaseTitle } from '../../ui/Title';
import { FormattedText } from './';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  max-width: 900px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 2.5rem 1.2rem;
  margin: 1rem auto 2rem;

  &:not(:last-child) {
    margin-bottom: 0;
  }

  ${media.greaterThan('tablet')`
    padding: 5rem 6.5rem 6rem;
    margin: 1rem auto 5rem;
    
    &:first-child {
      margin-top:5rem;
    }
  `}

  img {
    display: block;
    margin: 1.5rem 0;
    max-width: 100%;
  }

  ul {
    list-style-type: disc;
    padding-left: 16px;
    text-align: start;
  }

  ol {
    list-style-type: decimal;
    padding-left: 16px;
    text-align: start;
  }

  iframe {
    display: block;
    width: 100%;
    margin: 1.5rem 0;
  }

  p,
  li {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    text-align: initial;

    ${media.greaterThan('tablet')`
      font-size: ${({ theme }) => theme.fontSize.m};
    `}
  }

  a {
    text-decoration: none;
    color: #009b72;
    font-weight: 600;
  }

  h2 {
    margin: 1.5rem 0;
    font-size: ${({ theme }) => theme.fontSize.l};
    font-weight: ${({ theme }) => theme.fontWeight.medium};

    ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.ll};
  `}

  h3,h4,h5 {
    margin: 1.5rem 0;
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};

    ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.l};
  `}
  }
`;

const Breadcrumb = styled.div`
  color: #8f919d;
  font-size: ${({ theme }) => theme.fontSize.s};
  width: 100%;
  max-width: 900px;
  text-align: initial;
  margin: 1rem auto 0;
  padding-left: 0.5rem;
  text-transform: capitalize;

  ${media.greaterThan('tablet')`
    margin: 3rem auto 0.5rem;
    padding-left:0.5rem;
  `}

  &:not(:first-child) {
    display: none;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.darkGray};
    &:last-of-type {
      color: ${({ theme }) => theme.colors.mediumGray};
      text-transform: none;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  span {
    font-weight: 500;
  }
`;

const Title = styled(BaseTitle).attrs({ as: 'h1' })`
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 1rem;

  ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.xl};
  `}
`;

const CardTitle = styled(BaseTitle).attrs({ as: 'h2' })`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 1rem;

  ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.ll};
  `}
`;

const Submessage = styled.span`
  font-size: ${({ theme }) => theme.fontSize.ms};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme, color }) => (color ? color : theme.colors.mediumGray)};
  text-align: initial;
  margin-bottom: 2rem;

  ${media.greaterThan('tablet')`
      font-size: ${({ theme }) => theme.fontSize.m};
  `}
`;

const CardWrapper = styled.a`
  text-decoration: none;
`;

const HeadingWrapper = styled.div`
  margin: 0 auto 2rem;
  ${media.greaterThan('tablet')`
  display: flex;
  `}
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  max-width: 30px;
  max-height: 50px;

  ${media.greaterThan('tablet')`
    width:120px;
    height:120px;
    margin-right: 2.5rem;
    max-width: 80px;
    max-height: 80px;
    `}
`;
const Icon = styled.img`
  width: 100%;
  max-height: auto;
  display: block;
  margin: auto;
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme, color }) => (color ? color : theme.colors.mediumGray)};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  text-align: initial;
  display: inline-block;
  margin-bottom: 2rem;

  ${media.greaterThan('tablet')`
      font-size: ${({ theme }) => theme.fontSize.m};
    `}
`;

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 1;
  box-sizing: border-box;
  width: 100%;
  display: inline-block;
  min-width: 100%;

  &:last-child {
    border-bottom: 1px solid #d4dadf;
  }
`;

const ArticleWrapper = styled.div`
  border: 1px solid #d4dadf;
  padding: 1.5rem 2rem;
  border-bottom: 0;

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(242, 242, 242, 0.7);
    transition: opacity 0.5s;
  }

  &:not(:last-child) {
    border-bottom: 0;
  }
`;

const CategoryWrapper = styled.div`
  border-top: 1px solid #d4dadf;
  padding: 3rem 0 0.5rem;
`;

const KnowledgeCard = ({ data }) => {
  const { title, content, submessage, levelFormat, collectionPages, icon } =
    data;

  const isBrowser = () => typeof window !== 'undefined';
  const breadcrumbs = isBrowser
    ? window.location.pathname.split('/').slice(0)
    : '';
  const breadcrumbTitle = breadcrumbs[2].split('-').join(' ');
  const breadcrumbPath = breadcrumbs.slice(0, -1).join('/');

  //No plan to create it in another language in the near future
  const homepage = `${
    breadcrumbs[1] === 'es' ? 'https://help.getquipu.com/' : '/'
  }`;

  return (
    <>
      <Breadcrumb>
        <a href={homepage}>Inicio</a>
        <span> > </span>
        {breadcrumbs.length > 3 && (
          <>
            <a href={breadcrumbPath}>{breadcrumbTitle}</a>
            <span> > </span>
          </>
        )}
        <a href="">{title}</a>
      </Breadcrumb>

      <Wrapper>
        {levelFormat ? (
          <>
            <HeadingWrapper>
              <IconWrapper>
                <Icon src={icon.url} />
              </IconWrapper>
              <div>
                <Title>{title}</Title>
                <Submessage>{submessage}</Submessage>
              </div>
            </HeadingWrapper>

            {collectionPages.map((feature, i) => {
              const { knowledgeArticle, formattedText } = feature;
              return (
                <ContentWrapper key={i}>
                  {formattedText && (
                    <CategoryWrapper>
                      <FormattedText>{formattedText}</FormattedText>
                    </CategoryWrapper>
                  )}
                  {knowledgeArticle && (
                    <ArticleWrapper>
                      <CardWrapper href={knowledgeArticle.redirect.url}>
                        <CardTitle>{knowledgeArticle.title}</CardTitle>
                        <Description>
                          {knowledgeArticle.description}
                        </Description>
                      </CardWrapper>
                    </ArticleWrapper>
                  )}
                </ContentWrapper>
              );
            })}
          </>
        ) : (
          <>
            <Title>{title}</Title>
            <Submessage>{submessage}</Submessage>
            <div>
              <FormattedText>{content}</FormattedText>
            </div>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default KnowledgeCard;
