import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import { Link, BurgerIcon, CallToAction, Container, DropDown } from '../../ui';
import LogoSVG from '../../../assets/svg/logo.svg';
import TopBar from './TopBar';

const Wrap = styled.header`
  width: 100%;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ shadow, theme }) => shadow && theme.shadow.nav};
  ${media.lessThan('navigationBreak')`
    box-shadow: ${({ shadow, expandMobile, theme }) =>
      shadow && !expandMobile ? theme.shadow.nav : 'none'};
  `}
`;

const MobileWrap = styled.div`
  flex-grow: 0;
  flex-shrink: 1;
  ${media.lessThan('navigationBreak')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
  `}
`;

const Inner = styled(Container).attrs({ as: 'nav' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  overflow: hidden;
  ${media.lessThan('navigationBreak')`
    display: block;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0 !important;
  `}
`;

const Logo = styled.div`
  height: 36px;
  max-width: 330px;

  svg {
    height: 36px;
    width: auto;
  }
`;

const Links = styled.div.attrs(({ expandMobile, listHeightMobile }) => ({
  style: {
    height: expandMobile ? listHeightMobile : '0',
  },
}))`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  ${media.greaterThan('navigationBreak')`
    height: auto !important;
  `}
  ${media.lessThan('navigationBreak')`
    padding: ${({ expandMobile }) => (expandMobile ? '2rem 3rem' : '0 3rem')};
    left: 0;
    width: 100%;
    transition: height .20s ease-in-out, padding .20s ease-in-out;
    flex-direction: column;
    overflow-y: ${({ expandMobile }) => (expandMobile ? 'auto' : 'hidden')};
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 0;
    flex: 0;
    > ul {
      display: flex;
      flex-direction: column;
      text-align: start;
      width: 100%;
      > li {
        margin: .5rem 0;
      }
    }
  `}
`;

const Center = styled.ul`
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
  padding-left: 3rem;
`;

const Right = styled.ul`
  text-align: right;
  ${media.lessThan('navigationBreak')`
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    &:last-child {
      text-align: center;
    }
  `}
`;

const ListItem = styled.li`
  display: inline-block;
  padding-top: 0.5rem;
`;

const CentralLink = ({ href, children, active }) => (
  <Link path={href} menuLink active={active}>
    {children}
  </Link>
);

const Navigation = (props) => {
  const isDesktopHandle = (width) => {
    if (typeof window !== 'undefined') {
      return window.innerWidth > width ? true : false;
    }
  };
  const withShadowHandle = (size) => {
    if (typeof window !== 'undefined') {
      return window.scrollY > size ? true : false;
    }
  };
  const {
    locale,
    slug,
    navigation: { menuItems, loginButtons, topbar, dropdownMenu },
  } = props;
  const [shadow, setShadow] = useState(false);
  const [expandMobile, setExpandMobile] = useState(false);
  const [listHeightMobile, setListHeight] = useState(0);
  const [isDesktop, setIsDesktop] = useState(isDesktopHandle());
  const mobileWrapRef = useRef(null);

  const desktopAndShadowHandler = () => {
    setShadow(withShadowHandle(60));
    setIsDesktop(isDesktopHandle(1105));
  };

  const mobileHandler = () => {
    document.body.style.overflow = expandMobile && !isDesktop ? 'hidden' : '';
    mobileWrapRef &&
      setListHeight(
        typeof window !== 'undefined' &&
          window.innerHeight -
            mobileWrapRef.current.getBoundingClientRect().bottom
      );
  };

  useEffect(() => {
    window.addEventListener('scroll', desktopAndShadowHandler);
    window.addEventListener('resize', desktopAndShadowHandler);

    return () => {
      window.removeEventListener('scroll', desktopAndShadowHandler);
      window.removeEventListener('resize', desktopAndShadowHandler);
    };
  });

  useEffect(() => {
    desktopAndShadowHandler();
    mobileHandler();
  }, [expandMobile, topbar]);

  return (
    <Wrap shadow={shadow} expandMobile={expandMobile}>
      {topbar && <TopBar {...topbar} />}
      <Inner>
        <MobileWrap expandMobile={expandMobile} ref={mobileWrapRef}>
          <Logo>
            <Link path={locale === 'es' ? '/' : `/${locale}`}>
              <LogoSVG />
            </Link>
          </Logo>
          <BurgerIcon
            opened={expandMobile}
            onClick={() => setExpandMobile(!expandMobile)}
          />
        </MobileWrap>
        <Links expandMobile={expandMobile} listHeightMobile={listHeightMobile}>
          <Center>
            {menuItems.map(({ url, text }, i) => {
              const dropDown =
                dropdownMenu &&
                dropdownMenu.length &&
                dropdownMenu.find((d) => d.pageLink.text === text);
              const props = {
                href: url,
                active:
                  i !== menuItems.length - 1 &&
                  url &&
                  slug.includes(url.substring(url.lastIndexOf('/') + 1)),
                isDesktop,
              };
              return (
                <ListItem key={i}>
                  {dropDown ? (
                    <DropDown {...dropDown} {...props}>
                      {text}
                    </DropDown>
                  ) : (
                    <CentralLink
                      href={url}
                      active={
                        i !== menuItems.length - 1 &&
                        slug.includes(url.substring(url.lastIndexOf('/') + 1))
                      }
                    >
                      {text}
                    </CentralLink>
                  )}
                </ListItem>
              );
            })}
          </Center>
          <Right>
            {loginButtons.map((button, i) => {
              const ctaProps = {
                outline: i === 0,
                path: button.url,
                style: 'product',
                block: true,
                ...button,
              };
              return (
                <ListItem key={i} buttons>
                  {i === 0 ? (
                    <>
                      <CallToAction hideOnDesktop {...ctaProps} />
                      <CallToAction link hideOnMobile {...ctaProps} />
                    </>
                  ) : (
                    <CallToAction {...ctaProps} />
                  )}
                </ListItem>
              );
            })}
          </Right>
        </Links>
      </Inner>
    </Wrap>
  );
};

export default Navigation;
