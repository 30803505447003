import React from 'react';
import styled from 'styled-components';
import { media } from '../../style';
import CheckSVG from '../../assets/svg/check.svg';

const FeatureList = styled.ul`
  text-align: left;
  margin-top: 3rem;
`;
const FeatureItem = styled.li`
  display: flex;
  padding-left: 2rem;
  margin-bottom: 1.2rem;

  svg {
    width: 16px;
    flex-shrink: 0;
  }

  ${media.greaterThan('mobile')`
    margin-bottom: 2rem;
  `}
`;

const FeatureText = styled.p`
  margin-left: 2rem;
  color: ${({ theme }) => theme.colors.darkgray};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme, isBold }) =>
    theme.fontWeight[isBold ? 'medium' : 'regular']};
`;

const FeatureSubtext = styled.span`
  color: ${({ theme }) => theme.colors.mediumGray};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-left: 1rem;
`;

const FeatureItemChecks = ({ data }) => (
  <FeatureList>
    {data.map(({ featureItem, featureSubtext }, i) => (
      <FeatureItem key={i}>
        <CheckSVG />
        <FeatureText>{featureItem}</FeatureText>
        {featureSubtext && <FeatureSubtext>{featureSubtext}</FeatureSubtext>}
      </FeatureItem>
    ))}
  </FeatureList>
);

export default FeatureItemChecks;
