import React from 'react';
import styled from 'styled-components';

const Text = styled.h5`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.lineHeight.double};
  max-width: 560px;
  margin: 0 auto 5rem;
  text-align: center;
`;

const Paragraph = ({ children }) => <Text>{children}</Text>;

export default Paragraph;
