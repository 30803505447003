import { createGlobalStyle } from 'styled-components';
import { generateMedia } from 'styled-media-query';
import reset from 'styled-reset';
import * as fonts from '../assets/fonts';

export const theme = {
  colors: {
    black: '#000000',
    darkGray: '#34343D',
    mediumGray: '#8F8F9D',
    gray: '#C3C3D0',
    lightGray: '#E0E0E7',
    white: '#ffffff',
    teal: '#009B72',
    darkGreen: '#008260',
    blue: '#0372B9',
    paleBlue: '#E7F4F8',
    darkBlue: '#01578F',
    yellow: '#FABA00',
    pastelYellow: '#FCE6A6',
    pastelSkin: '#FEE7D0',
    lightRed: '#df4b32',
    red: '#c21609',
    darkRed: '#970b00',
    paleTeal: '#E4F5F3',
    pastelteal: '#92DACF',
    lightTeal: '#22b49e',
    darkTeal: '#015F61',
    paleOrange: '#FEF7E5',
    softGray: '#F9F9F9',
    gold: '#FFA83C',
  },
  fontSize: {
    // ref: 10px
    xs: '1rem',
    s: '1.4rem',
    sm: '1.6rem',
    m: '1.8rem',
    l: '2.1rem',
    ll: '2.4rem',
    xl: '3.2rem',
    xll: '4rem',
    xxl: '4.8rem',
  },
  fontWeight: {
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
  },
  lineHeight: {
    default: '1.3',
    medium: '1.5',
    double: '2',
    super: '2.5',
  },
  shadow: {
    cta: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    nav: '0 0 34px -10px rgba(0, 0, 0, 0.4)',
    card: '2px 2px 12px 4px rgba(195, 195, 208, 0.2)',
    postCard: '0 2px 6px 2px rgba(138, 203, 223, 0.3)',
    pricingCard: '0 2px 10px 5px rgba(195, 195, 208, 0.3)',
    promoCard: '0 2px 14px 6px rgba(0, 0, 0, 0.2)',
    box: '0 0 50px -10px rgba(0, 0, 0, .3)',
  },
  sizing: {
    navHeightExpanded: '470px',
    defaultPadding: '0 3rem',
    paddingLeft: '0 0 0 1rem',
    paddingRight: '0 1rem 0 0',
    noPadding: '0',
    maxItemWidth: '645px',
    marginLeftAuto: '0 0 0 auto',
    marginRightAuto: '0 auto 0 0',
  },
};

export const media = generateMedia({
  mobile: '576px',
  tablet: '769px',
  desktop: '992px',
  largeDesktop: '1200px',
  extraLargeDesktop: '1400px',
  navigationBreak: '1105px',
});

export const GlobalStyle = createGlobalStyle`
  ${reset}
  html {
    font-size: 10px;
  }
  body {
    @font-face {
      font-family: 'IBM Plex Sans';
      src: url('${fonts.IBMPlexSansExtraLight}') format('ttf');
      font-weight: 300;
    }
    @font-face {
      font-family: 'IBM Plex Sans';
      src: url('${fonts.IBMPlexSansLight}') format('ttf');
      font-weight: 400;
    }
    @font-face {
      font-family: 'IBM Plex Sans';
      src: url('${fonts.IBMPlexSansMedium}') format('ttf');
      font-weight: 500;
    }
    @font-face {
      font-family: 'IBM Plex Sans';
      src: url('${fonts.IBMPlexSansRegular}') format('ttf');
      font-weight: 600;
    }
    @font-face {
      font-family: 'IBM Plex Sans';
      src: url('${fonts.IBMPlexSansSemiBold}') format('ttf');
      font-weight: 700;
    }
    @font-face {
      font-family: 'IBM Plex Sans';
      src: url('${fonts.IBMPlexSansBold}') format('ttf');
      font-weight: 800;
    }
    font-family: "IBM Plex Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSize.m};
    -webkit-font-smoothing: antialiased;
    text-decoration-style: solid;
    text-decoration-line: none;
    text-size-adjust: 100%;
    touch-action: manipulation;
    background-color: transparent;
    overflow-x: hidden;
    position: relative;
    margin: 0;
    font-size: 1.5rem;
    letter-spacing: normal;
    line-height: ${({ theme }) => theme.lineHeight.default};
    color: ${({ theme }) => theme.colors.black};
  }

  *, ::after, ::before {
    box-sizing: border-box;
  }
`;
