import React from 'react'
import styled from 'styled-components'
import { Image } from '../../ui'
import { FormattedText } from './'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LogosWrap = styled.div`
  display: flex;
`

const FormattedTextWrapper = styled.div`
  font-size: ${ ({ theme }) => theme.fontSize.l };
  margin-top: 20px;
`

const PromoApp = props => {
  const { image, styledTitle, storeLogos } = props;
  const imgProps = { image, className: 'lazyload' };

  return (
    <Wrapper>
      <Image { ...imgProps } />
      <FormattedTextWrapper>
        <FormattedText>{ styledTitle }</FormattedText>
      </FormattedTextWrapper>
      <LogosWrap>
        { storeLogos.map(({ logoImage, link }, i) => {
          const imageProps = { image: logoImage, regularLink: link, className: 'lazyload' }
          return (
            <Image key={ i } { ...imageProps } />
          )
        })}
      </LogosWrap>
    </Wrapper>
  )
}

export default PromoApp