import React from 'react'
import styled, { keyframes } from 'styled-components'

const loader = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div`
  position: absolute;
  left: ${ ({ width }) => `calc(50% - ${ width ? width / 2 : 10 }px)` };
  top: ${ ({ height }) => `calc(50% - ${ height ? height / 2 : 10 }px)` };
  width: ${ ({ width }) => width || 20 }px;
  height: ${ ({ height }) => height || 20 }px;
`

const Donut = styled.div`
  width: 100%;
  height: 100%;
  border: ${ ({ border }) => `${ border || 2 }px solid rgba(0,0,0,0.10)` }; 
  ${ ({ theme, secondary }) => `border-left-color: ${ theme.colors[ secondary ? 'lightGray' : 'lightTeal'] }` };
  border-radius: 50%;
  animation: ${ loader } 0.8s cubic-bezier(.45,.2,.65,.93) infinite;
`

const Loader = ({ height, width, border, secondary, className }) => (
  <Wrapper width={ width } height={ height } className={ className }>
    <Donut secondary={ secondary } border={ border } />
  </Wrapper>
)

export default Loader