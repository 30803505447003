import React from 'react';
import styled from 'styled-components';
import { media } from '../../style';

const Li = styled.li`
  display: ${ ({ inline }) => inline ? 'inline' : 'list-item' };
  
  ${ media.greaterThan('desktop')`
    max-width: ${ ({ maxWidth }) => maxWidth };
  `}
`

const FooterLi = styled(Li)`
  margin: 3rem 0;
  line-height: ${({ theme }) => theme.lineHeight.medium};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  ${media.greaterThan('desktop')`
    max-width: ${({ maxWidth }) => maxWidth};
  `}

  &:nth-child(2) {
    margin-top: 1rem;
  }
`;

const ListItem = ({ children, footer, inline, itemProp, itemScope, itemType, onClick, maxWidth }) => {
  const microData = { itemProp, itemScope, itemType };
  const liProps = { onClick, inline, maxWidth, ...microData };
  const footerLiProps = { inline, ...microData, maxWidth };

  return footer 
    ? <FooterLi { ...footerLiProps }>{ children }</FooterLi> 
    : <Li { ...liProps }>{ children }</Li> 
};

export default ListItem;