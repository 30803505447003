import React from 'react'
import styled from 'styled-components'
import CheckIcon from '../../assets/svg/check.svg'

const Wrapper = styled.div`
  display: flex;
  align-items: normal;
  cursor: pointer;
  user-select: none;
`

const Inline = styled.div`
  display: inline-block;
`

const Input = styled.button`
  width: 20px;
  height: 20px;
  background-color: ${ ({ theme, checked }) => theme.colors[ checked ? 'teal' : 'white' ] };
  border: 2px solid ${ ({ theme, checked }) => theme.colors[ checked ? 'teal' : 'mediumGray' ] };
  border-radius: 2px;
  margin-right: 10px;
  margin-top: -1px;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0;
  transition: 0.15s ease;
  :active, :hover, :focus{
    outline: none;
    border-color: ${ ({ theme }) => theme.colors.teal };;
  }
  & svg{
    opacity: ${ ({ checked }) => checked ? 1 : 0 };
    transform: scale(0.8);
    position: relative;
    & g{
      fill: white;
    }
  }
`

const Checkbox = ({ children, checked, onClick }) => {
  return(
    <Wrapper onClick={ onClick }>
      <Input checked={ checked }>
        <CheckIcon/>
      </Input>
      <Inline>
        { children }
      </Inline>
    </Wrapper>
  )
}

export default Checkbox