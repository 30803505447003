import axios from 'axios';

const request = async (url, method, data) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.quipu.v2+json',
  };
  const options = {
    url,
    method,
    data,
    headers,
  };
  return axios.request(options);
};

export default request;
