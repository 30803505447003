import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { Container } from '../../ui';

const Wrap = styled.div`
  padding: 1rem 0;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
`;

const StickyWrap = styled.div`
  padding: 1rem 0;
  position: sticky;
  width: 100%;
  z-index: 101;
  background-color: ${({ bgColor }) => bgColor};
`;

const InnerWrap = styled(Container)`
  display: flex;
`;

const Icon = styled.img`
  &:last-child {
    cursor: pointer;
  }
`;

const Text = styled.div`
  padding: 0 1rem;
  flex-grow: 1;

  a {
    text-decoration: none;
    color: currentColor;
  }
  a:hover {
    color: blue;
  }
  strong {
    font-weight: 600;
  }
  strong:hover {
    font-weight: 700;
  }
`;

const TopBar = (props) => {
  const {
    mobileIcon,
    mobileText,
    desktopIcon,
    desktopText,
    cancelIcon,
    backgroundColor,
    sticky,
  } = props;
  const desktopBreak = 576;
  const expiresTime = 604800;
  const cookieName = 'cookies_topbar_shown';
  const isMobile = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth < desktopBreak ? true : false;
    }
  };
  const [mobile, setMobile] = useState(isMobile());
  const [cookies, setCookie] = useCookies([cookieName]);
  const handleClose = () =>
    setCookie(cookieName, 'true', { maxAge: expiresTime });

  useEffect(() => {
    const eventHandler = () => setMobile(isMobile());
    window.addEventListener('resize', eventHandler);

    return () => window.removeEventListener('resize', eventHandler);
  });

  if (typeof window !== 'undefined') {
    return cookies[cookieName] ? null : (
      <>
        {sticky ? (
          <StickyWrap bgColor={backgroundColor && backgroundColor.colorhex.hex}>
            <InnerWrap>
              <Icon src={mobile ? mobileIcon.url : desktopIcon.url} />
              <Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: mobile ? mobileText : desktopText,
                  }}
                />
              </Text>
              <Icon src={cancelIcon.url} onClick={handleClose} />
            </InnerWrap>
          </StickyWrap>
        ) : (
          <Wrap bgColor={backgroundColor && backgroundColor.colorhex.hex}>
            <InnerWrap>
              <Icon src={mobile ? mobileIcon.url : desktopIcon.url} />
              <Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: mobile ? mobileText : desktopText,
                  }}
                />
              </Text>
              <Icon src={cancelIcon.url} onClick={handleClose} />
            </InnerWrap>
          </Wrap>
        )}
      </>
    );
  } else {
    return null;
  }
};

export default TopBar;
