import React from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import ArrowSVG from '../../../assets/svg/arrow.svg'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  margin-top: 1rem;

  ${ media.greaterThan('tablet')`
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem 10rem;
  `}
`

const CardWrapper = styled.a`
  background-color: ${ ({ theme }) => theme.colors.white };
  max-width: 550px;
  width: 100%;
  min-height: 120px;
  box-shadow: ${ ({ theme }) => theme.shadow.card };
  display: flex;
  align-items: flex-start;
  padding: 3rem;
  text-decoration: none;
  color: ${ ({ theme }) => theme.colors.black };
  border-radius: 4px;

  > * {
    flex-shrink: 0;
  }

  svg {
    align-self: center;
    transition: transform .35s ease;
  }

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
`

const ContentWrapper = styled.div`
  padding-left: 2rem;
  text-align: left;
  flex-grow: 1;
  flex-shrink: 1;
`

const JobPosition = styled.h2`
  margin-bottom: 1.5rem;
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const Location = styled.p`
  color: ${ ({ theme }) => theme.colors.mediumGray };
  font-size: ${ ({ theme }) => theme.fontSize.sm };
`

const JobCard = props => {

  return (
    <Wrapper>
      { props.data.map((job, i) => {
        const { icon, jobPosition, location, externalLink } = job;

        return (
          <CardWrapper key={ i } href={ externalLink } target='_blank' >
            <img src={ icon.url } alt='quipu-imago' />
            <ContentWrapper>
              <JobPosition>{ jobPosition }</JobPosition>
              <Location>{ location }</Location>
            </ContentWrapper>
            <ArrowSVG />
          </CardWrapper>
        ) 
      })}
    </Wrapper>
  )
};

export default JobCard;