import React from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import { CallToAction } from '../../ui';
import FormattedText from './FormattedText';

const CARD_WIDTH = 280;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  ${media.greaterThan('desktop')`
  flex-direction: row;
  margin: 0; 
`}
`;

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 2rem;
  padding: 3rem 4rem 1.5rem;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadow.pricingCard};
  text-align: center;
  flex-shrink: 0;
  width: ${() => `${CARD_WIDTH}px`};
  display: flex;
  flex-direction: column;

  ${media.greaterThan('mobile')`
    flex-shrink: 1;
    margin: 2rem 1.5rem;
    width: 85%;
  `}

  ${media.greaterThan('tablet')`
    padding: 4rem 2rem;
    max-width: 450px;
  `}
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 1.5rem;
`;

const ContentWrapper = styled.div`
  margin: 2rem;
`;

const Image = styled.img`
  margin: 1rem 0 2rem;
  max-width: 100%;
  border-radius: 6px;
`;

const SegmentCard = (props) => {
  const { data } = props;
  return (
    <Wrapper>
      {data.map((card, i) => {
        const { title, image, callToAction, text } = card;

        return (
          <Card key={i}>
            <Title>{title}</Title>
            {image && <Image src={image.url} alt={image.alt} />}
            <CallToAction path={callToAction.url} {...callToAction} />
            <ContentWrapper>
              <FormattedText>{text}</FormattedText>
            </ContentWrapper>
          </Card>
        );
      })}
    </Wrapper>
  );
};

export default SegmentCard;
