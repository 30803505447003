import React from 'react';
import { CallToAction } from '../../ui';
import styled from 'styled-components';
import { media } from '../../../style';

const ComponentWrapper = styled.div`
  display: flex;
  margin: 0 auto 0.5rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 60vh;

  ${media.greaterThan('desktop')`
    width: 50%;
  `}

  ${media.lessThan('desktop')`
    width: 95%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
  `}

  iframe {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const CallToActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const VideoCard = (props) => {
  const { data } = props;
  const { video, callToAction } = data[0];
  const vimeoID = video.url.substring(video.url.lastIndexOf('/') + 1);

  return (
    <>
      <ComponentWrapper>
        <iframe
          src={`https://player.vimeo.com/video/${vimeoID}`}
          pictureInPicture={true}
          gyroscope={true}
          encryptedMedia={true}
          accelerometer={true}
          allowFullScreen={true}
          frameBorder={false}
        />
      </ComponentWrapper>
      {callToAction && (
        <CallToActionWrapper>
          <CallToAction path={callToAction.url} {...callToAction} />
        </CallToActionWrapper>
      )}
    </>
  );
};

export default VideoCard;
