import React from 'react';
import styled from 'styled-components';
import { media } from '../../style';

const ListWrap = styled.ul`
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding-left: 0;
  ${({ langList }) =>
    langList &&
    `
    margin-top: 2rem;
  `}
`;

const List = ({ children, itemScope, itemProp, itemType, langList }) => {
  const microData = { itemScope, itemProp, itemType };

  return (
    <ListWrap langList={langList} {...microData}>
      {children}
    </ListWrap>
  );
};

export default List;
