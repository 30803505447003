import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ItemsCarousel from 'react-items-carousel';
import { useSwipeable } from 'react-swipeable';
import { SaasReview, PartnersReview, TeuReview, WorkerReview } from '../Item/ModularContent/reviews';
import { Bullets, Bullet } from '../Item/ModularContent/reviews/components'
import ArrowSVG from '../../assets/svg/arrow-round.svg';
import { Video } from './';
import useInterval from '../../helpers/useInterval';

const StyledCarousel = styled(ItemsCarousel)`
  margin-bottom: 3rem;
`

const SVGWrap = styled.div`
  transform: ${ ({ left }) => left && 'rotate(180deg)' };

  svg path {
    fill: ${ ({ theme }) => theme.colors.teal };
  }

  &:hover {
    svg path {
      fill: ${ ({ theme }) => theme.colors.darkTeal };
    }
  }
`

const CarouselNew = ({ data, type }) => {
  const isDesktop = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth > 1120 ? true : false;
    }
  };
  const [active, setActive] = useState(0);
  const [numberOfCards, setNumberOfCards] = useState(isDesktop());
  const [showVideo, setShowVideo] = useState(false);
  const [videoReview, setVideoReview] = useState(null);
  const [isRunning, setIsRunning] = useState(true);

  useInterval(() => {
    setActive((active + 1) % data.length);
  }, isRunning ? 8000 : null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const eventHandler = () => setNumberOfCards(isDesktop());
      window.addEventListener('resize', eventHandler);
      return () => window.removeEventListener('resize', eventHandler);
    }
  }, []);

  const handleChange = value => {
    setActive(value);
  };
  const toggleVideo = video => {
    setShowVideo(!showVideo);
    video && setVideoReview(video);
  }

  const renderReview = () => data.map((review, i) => {
    let templateReview = null;
    let reviewProps = {
      key: i,
      ...review,
      onMouseEnter: () => setIsRunning(false),
      onMouseLeave: () => setIsRunning(true)
    }

    switch (type) {
      case 'clientSaas':
        templateReview = <SaasReview { ...reviewProps } />
        break;
      case 'partnerTestimony':
        templateReview = <PartnersReview { ...reviewProps } onClick={ video => toggleVideo(video) } />
        break;
      case 'clientTeu':
        templateReview = <TeuReview { ...reviewProps } />
        break;
      case 'workerTestimony':
        templateReview = <WorkerReview { ...reviewProps } />
    }

    return templateReview;
  });

  const howManyCards = () => {
    if (type === 'clientSaas' || type === 'workerTestimony') {
      return numberOfCards ? 2 : 1;
    } else {
      return 1;
    }
  };

  const carouselProps = {
    numberOfCards: howManyCards(),
    gutter: 0,
    showSlither: false,
    requestToChangeActive: value => handleChange(value),
    activeItemIndex: active,
    activePosition: 'center',
    chevronWidth: 24,
    rightChevron: <SVGWrap><ArrowSVG /></SVGWrap>,
    leftChevron: <SVGWrap left><ArrowSVG /></SVGWrap>,
    outsideChevron: false
  };
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleChange(active < data.length ? active + 1 : active),
    onSwipedRight: () => handleChange(active > 0 ? active - 1 : active),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <>
      <StyledCarousel { ...carouselProps } { ...swipeHandlers }>
        { renderReview() }
      </StyledCarousel>
      <Bullets>
        { data.map((item, i) => <Bullet key={ i } active={ i === active } onClick={ () => handleChange(i) } /> )}
      </Bullets>
      { type === 'partnerTestimony' && showVideo && <Video { ...videoReview } onClick={ toggleVideo } /> }
    </>
  );
};

export default CarouselNew;
