import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import { CallToAction, FeatureItemChecks } from '../../ui';
import { Bullets, Bullet } from './reviews/components';
import { useSwipeable } from 'react-swipeable';

const CARD_WIDTH = 280;

const Wrapper = styled.div`
  text-align: center;
`;

const CardsWrapper = styled.div`
  overflow-x: visible;
`;

const Carousel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: normal;
  margin: 4rem 0 2rem ${({ active }) => `-${active * CARD_WIDTH * 1.15}px`};
  transition: margin 0.35s ease-out;
  width: 100px;

  ${media.greaterThan('mobile')`
    justify-content: center;
    margin-left: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: unset;
  `}
`;


const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem auto;
  padding-bottom: 4rem;
 

  ${media.greaterThan('mobile')`
  flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: unset;
  `}
`;

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 3rem 0 1rem;
  padding: 3rem 2rem 1.5rem;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadow.pricingCard};
  text-align: center;
  flex-shrink: 0;
  width: ${() => `${CARD_WIDTH}px`};
  display: flex;
  flex-direction: column;

  /* Selector to fix the CTA text collapse */
  & a {
    padding: 1.3rem 1rem;
  }

  ${media.greaterThan('mobile')`
    flex-shrink: 1;
    margin: 0 1.5rem;
    width: 85%;
  `}

  ${media.greaterThan('tablet')`
    padding: 4rem 2rem;
    max-width: 450px;
  `}
  
  ${media.greaterThan('largeDesktop')`
    padding: 4rem 5rem;
  `}
`;


const VerticalCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 2rem auto;
  padding: 3rem 2rem 1.5rem;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadow.pricingCard};
  text-align: center;
  flex-shrink: 0;
  width: 90%;
  display: flex;
  flex-direction: column;

  /* Selector to fix the CTA text collapse */
  & a {
    padding: 1.3rem 1rem;
  }

  ${media.greaterThan('mobile')`
    flex-shrink: 1;
    margin: 0 1.5rem;
    width: 85%;
  `}

  ${media.greaterThan('tablet')`
    padding: 4rem 2rem;
    max-width: 450px;
  `}
  
  ${media.greaterThan('largeDesktop')`
    padding: 4rem 5rem;
  `}
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 1.5rem;

  ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.xll};
  `}
`;

const Image = styled.img`
  margin: 1rem 0 2rem;
  max-width: 100%;
`;

const IntroText = styled.p`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 2.5rem;
`;

const PriceWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem;
  height: 80px;

  ${media.greaterThan('tablet')`
    height: 80px;
  `}

  ${media.greaterThan('desktop')`
    height: 100px;
  `}
`;

const Price = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.darkGray};

  ${media.greaterThan('desktop')`
    font-size: ${({ theme }) => theme.fontSize.xxl};
  `}
`;

const SubPrice = styled.p`
  color: ${({ theme }) => theme.colors.mediumGray};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 2rem;
`;

const AnchorLink = styled.a`
  margin-top: auto;
  color: ${({ theme }) => theme.colors.mediumGray};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-decoration: underline;
  cursor: pointer;
`;

const BulletsWrap = styled.div`
  ${media.greaterThan('mobile')`
    display: none;
  `}
`;

const ToggleSwitch = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  width: 260px;
  margin: 1rem auto 2rem;
  padding: 1rem 0;
  border-radius: 20px;
  display: flex;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    width: 53%;
    background-color: ${({ theme }) => theme.colors.darkGray};
    position: absolute;
    height: 100%;
    border-radius: 20px;
    top: 0;
    left: 0;
    transform: ${({ active }) => active && 'translateX(90%)'};
    transition: transform 0.35s ease-out;
  }
`;

const Option = styled.span`
  color: ${({ theme, active }) =>
    theme.colors[active ? 'white' : 'mediumGray']};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  width: 130px;
  z-index: 1;
`;

const PricingCard = (props) => {
  const { data, lateralScroll } = props;
  const cardWrapper = useRef(null);
  const [active, setActive] = useState(0);

  const handleChange = value => {
    setActive(value);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleChange(active < data.length - 1 ? active + 1 : active),
    onSwipedRight: () => handleChange(active > 0 ? active - 1 : active),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const goTo = (active) => () => {
    setActive(active)
  };

  const { toggleSwitch } = data[0];
  const [monthlyPlan, setMonthlyPlan] = useState(false);

  const handleAnchor = () =>
    window.scroll({
      top: cardWrapper.current.clientHeight + 700,
      behavior: 'smooth',
    });

  return (
    <Wrapper ref={cardWrapper}>
      {data[0].toggle ? (
        <ToggleSwitch
          onClick={() => setMonthlyPlan(!monthlyPlan)}
          active={!monthlyPlan}
        >
          {toggleSwitch.map(({ optionText }, i) => (
            <Option active={i === 0 ? monthlyPlan : !monthlyPlan} key={i}>
              {optionText}
            </Option>
          ))}
        </ToggleSwitch>
      ) : null}

      <CardsWrapper>
        {lateralScroll ?  (
          <>
        <Carousel active={active} {...handlers}>
          {data.map((card, i) => {
            const {
              title,
              image,
              introduction,
              monthlyPrice,
              monthlySubmessage,
              yearlyPrice,
              yearlySubmessage,
              calltoaction,
              features,
              pricingPageCard,
              anchorLink,
            } = card;
            const isNotFirstCard = i !== 0;
            return (
              
              <Card key={i}>
                <Title>{title}</Title>
                {image && (
                  <Image
                    data-src={image.url}
                    alt={image.alt}
                    className='lazyload'
                  />
                )}
                <IntroText>{introduction}</IntroText>
                <PriceWrap>
                  <Price>{monthlyPlan ? monthlyPrice : yearlyPrice}</Price>
                  <SubPrice>
                    {monthlyPlan ? monthlySubmessage : yearlySubmessage}
                  </SubPrice>
                </PriceWrap>
                <CallToAction path={calltoaction.url} {...calltoaction} />
                <FeatureItemChecks data={features} />
                {pricingPageCard ? (
                  <AnchorLink onClick={handleAnchor}>
                    {anchorLink.text}
                  </AnchorLink>
                ) : (
                  <AnchorLink href={anchorLink.url}>
                    {anchorLink.text}
                  </AnchorLink>
                )}
              </Card>
            );
          })}
        </Carousel>
                <BulletsWrap>
                <Bullets>
                  {data.map((item, i) => (
                    <Bullet key={i} active={active === i} onClick={goTo(i)} />
                  ))}
                </Bullets>
              </BulletsWrap>
              </>
        ) : (
          <Vertical>
          {data.map((card, i) => {
            const {
              title,
              image,
              introduction,
              monthlyPrice,
              monthlySubmessage,
              yearlyPrice,
              yearlySubmessage,
              calltoaction,
              features,
              pricingPageCard,
              anchorLink,
            } = card;
            const isNotFirstCard = i !== 0;
            return (
              <VerticalCard key={i}>
                <Title>{title}</Title>
                {image && (
                  <Image
                    data-src={image.url}
                    alt={image.alt}
                    className="lazyload"
                  />
                )}
                <IntroText>{introduction}</IntroText>
                <PriceWrap>
                  <Price>{monthlyPlan ? monthlyPrice : yearlyPrice}</Price>
                  <SubPrice>
                    {monthlyPlan ? monthlySubmessage : yearlySubmessage}
                  </SubPrice>
                </PriceWrap>
                <CallToAction path={calltoaction.url} {...calltoaction} />
                <FeatureItemChecks data={features} />
                {pricingPageCard ? (
                  <AnchorLink onClick={handleAnchor}>
                    {anchorLink.text}
                  </AnchorLink>
                ) : (
                  <AnchorLink href={anchorLink.url}>
                    {anchorLink.text}
                  </AnchorLink>
                )}
              </VerticalCard>
            );
          })}
          </Vertical>
        )}

      </CardsWrapper>
    </Wrapper>
  );
};

export default PricingCard;