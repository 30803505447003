import axios from 'axios';

const requestToZapier = async (url, method, data) => {
  const encodeForm = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };

  let options = {
    url,
    method,
    data: encodeForm(data),
  };

  return axios.request(options);
};

export default requestToZapier;
