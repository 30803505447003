import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import { CallToAction } from '../../ui';
import ArrowSVG from '../../../assets/svg/arrow-round.svg';
import ItemsCarousel from 'react-items-carousel';
import { Bullet, Bullets } from '../../Item/ModularContent/reviews/components';
import { useSwipeable } from 'react-swipeable';
import useInterval from '../../../helpers/useInterval';

const CarouselWrapper = styled.div`
  height: 80vh;
  width: 46vh;
  margin: 0 auto 6rem;
  padding: 1rem;

  ${media.greaterThan('mobile')`
    justify-content: center;
    height: 45vh;
    width: 80vh;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 6rem;
  `}
  ${media.greaterThan('tablet')`
    margin: auto;
    min-width: 90%;
    height: 25%;
`}
`;

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 2rem;
  text-align: center;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 0;
  ${media.greaterThan('mobile')`
    flex-shrink: 1;
    margin: 0 2rem;
    height: 100%;
    width: 90%;
    margin: 0 2rem;
    flex-direction: row;
  `}

  ${media.greaterThan('tablet')`
    margin: 0 4rem;
  `}
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.darkGreen};
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 1.5rem;
  ${media.greaterThan('mobile')`
  font-size: ${({ theme }) => theme.fontSize.l};
    text-align: left;
    margin-left: 3rem;
  `};
  ${media.greaterThan('tablet')`
     font-size: ${({ theme }) => theme.fontSize.xl};
  `}
`;

const Subtitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: 1.5rem 0;
  ${media.greaterThan('mobile')`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
   text-align: left;
   margin-left: 3rem;
  `};
  ${media.greaterThan('tablet')`
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`}
`;

const Image = styled.img`
  margin: auto
  width: 50%;
  max-height:16rem;
  ${media.greaterThan('mobile')`
   width: 50%;
   max-height:80%;
`};
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 2rem;
  text-align: justify;
  margin: 0 2rem;
  ${media.greaterThan('mobile')`
  font-size: ${({ theme }) => theme.fontSize.m};
   text-align: left;
   margin-left: 3rem;
`};
  ${media.greaterThan('tablet')`
  font-size: ${({ theme }) => theme.fontSize.l};
  line-height: 3rem;
`}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${media.greaterThan('mobile')`
   width: 40%;
   margin: auto 0;
`};
`;

const SVGWrap = styled.div`
  transform: ${({ left }) => left && 'rotate(180deg)'};

  svg path {
    fill: ${({ theme }) => theme.colors.teal};
  }

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.darkTeal};
    }
  }
`;

const CallToActionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CarouselCard = (props) => {
  const { data, calltoaction } = props;

  const isMobile = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth < 576 ? true : false;
    }
  };

  const [active, setActive] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const [mobileView, setMobileView] = useState(isMobile());
  const onMouseEnter = () => setIsRunning(false);
  const onMouseLeave = () => setIsRunning(true);

  useInterval(
    () => {
      setActive((active + 1) % data.length);
    },
    isRunning ? 8000 : null
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const eventHandler = () => setMobileView(isMobile());
      window.addEventListener('resize', eventHandler);
      return () => window.removeEventListener('resize', eventHandler);
    }
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      handleChange(active < data.length - 1 ? active + 1 : active),
    onSwipedRight: () => handleChange(active > 0 ? active - 1 : active),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleChange = (value) => {
    setActive(value);
  };

  return (
    <CarouselWrapper>
      <ItemsCarousel
        numberOfCards={1}
        gutter={0}
        showSlither={false}
        requestToChangeActive={(value) => handleChange(value)}
        activeItemIndex={active}
        activePosition={'center'}
        rightChevron={
          <SVGWrap>
            <ArrowSVG />
          </SVGWrap>
        }
        leftChevron={
          <SVGWrap left>
            <ArrowSVG />
          </SVGWrap>
        }
        outsideChevron={false}
        alwaysShowChevrons={true}
        chevronWidth={10}
        {...handlers}
        {...onMouseEnter}
        {...onMouseLeave}
      >
        {data.map((card, i) => {
          const { title, image, subtitle, description } = card;
          return (
            <Card key={i} active={i === active} onClick={() => handleChange(i)}>
              {mobileView ? (
                <>
                  <Title>{title}</Title>
                  <Image
                    data-src={image.url}
                    alt={image.alt}
                    className='lazyload'
                  />
                  <Content>
                    <Subtitle>{subtitle}</Subtitle>
                    <Description>{description}</Description>
                  </Content>
                </>
              ) : (
                <>
                  <Image
                    data-src={image.url}
                    alt={image.alt}
                    className='lazyload'
                  />
                  <Content>
                    <Title>{title}</Title>
                    <Subtitle>{subtitle}</Subtitle>
                    <Description>{description}</Description>
                  </Content>
                </>
              )}
            </Card>
          );
        })}
      </ItemsCarousel>
      <Bullets>
        {data.map((item, i) => (
          <Bullet
            key={i}
            active={i === active}
            onClick={() => handleChange(i)}
          />
        ))}
      </Bullets>

      <CallToActionWrapper>
        <CallToAction path={calltoaction.url} {...calltoaction} />
      </CallToActionWrapper>
    </CarouselWrapper>
  );
};

export default CarouselCard;
