import React from 'react';
import styled from 'styled-components';
import { media } from '../../style';

export const BaseTitle = styled.p`
  text-align: ${({ textAlign }) => textAlign && textAlign};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};
  margin-top: 0;
  margin-bottom: 2rem;
`;

const PageTitle = styled(BaseTitle).attrs({ as: 'h1' })`
  line-height: ${({ theme }) => theme.lineHeight.medium};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${media.greaterThan('tablet')`
		line-height: ${({ theme }) => theme.lineHeight.default};
		color: ${({ theme }) => theme.colors.black};
		font-size: ${({ theme }) => theme.fontSize.xl};
	`}
`;

const SectionTitle = styled(BaseTitle).attrs({ as: 'h2' })`
  text-align: ${({ textAlign }) => (textAlign === 'none' ? 'left' : 'center')};
  margin-bottom: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.ll};
  margin-left: auto;
  margin-right: auto;

  ${media.greaterThan('tablet')`
		max-width: unset;
		font-size: ${({ theme }) => theme.fontSize.xl};
	`}
`;

const ItemTitle = styled(BaseTitle).attrs({ as: 'h2' })`
  font-size: ${({ theme }) => theme.fontSize.ll};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: 2rem;
  margin-top: 5rem;

  ${media.greaterThan('tablet')`
		font-size: ${({ theme }) => theme.fontSize.xl};
		text-align: left;
		margin-top: 0;
	`}
`;

const Title = (props) => {
  const { children, pageTitle, itemTitle, textAlign, color } = props;

  return pageTitle ? (
    <PageTitle textAlign={textAlign} color={color}>
      {children}
    </PageTitle>
  ) : itemTitle ? (
    <ItemTitle color={color}>{children}</ItemTitle>
  ) : (
    <SectionTitle textAlign={textAlign} color={color}>
      {children}
    </SectionTitle>
  );
};

export default Title;
