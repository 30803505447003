import React, { Fragment, useEffect, useReducer, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { media } from '../../../style';
import { Button } from '../../ui/CallToAction';
import { Link, Checkbox } from '../../ui';

import testEmail from '../../../helpers/test-email';
import testPassword from '../../../helpers/test-password';
import testPhone from '../../../helpers/test-phone';
import request from '../../../helpers/request';
import requestToZapier from '../../../helpers/requestToZapier';

import HideIcon from '../../../assets/svg/hide.svg';
import ShowIcon from '../../../assets/svg/show.svg';
import CheckIcon from '../../../assets/svg/check.svg';

const COOKIE_AFF = 'affiliate_token';
const COOKIE_GIFT_CARD = 'gift_card';

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 3rem auto 1rem;
  width: 320px;

  ${media.greaterThan('tablet')`
    flex-direction: ${({ flexDirection }) =>
      flexDirection ? 'column' : 'row'};
    align-items: ${({ flexDirection }) =>
      flexDirection ? 'stretch' : 'center'};
    justify-content: center;
    min-width: ${({ flexDirection }) => (flexDirection ? 260 : 700)}px;
    & > *{
      ${({ flexDirection }) =>
        !flexDirection ? 'margin-bottom: 0 !important;' : ''};
    }
  `}

  ${media.lessThan('tablet')`
    max-width: 100%;
  `}
`;

const InputWrapper = styled.div`
  margin-bottom: ${({ flexDirection }) => (flexDirection ? '1rem' : '0')};
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 5.6rem;
  border: 1.5px solid
    ${({ theme, error }) => theme.colors[error ? 'lightRed' : 'gray']};
  border-radius: 4px;
  margin-bottom: ${({ labelOnInput, error }) =>
    labelOnInput && !error ? '2rem' : error ? '0' : 'inherit'};
  transition: border-color 0.15s ease;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  :focus-within,
  :hover {
    border: 1.5px solid
      ${({ theme, error }) => theme.colors[error ? 'red' : 'mediumGray']};
    > label {
      color: ${({ theme, error }) =>
        theme.colors[error ? 'red' : 'mediumGray']};
    }
  }

  label {
    font-size: ${({ theme }) => theme.fontSize.s};
    color: ${({ theme, error }) => theme.colors[error ? 'lightRed' : 'gray']};
    background-color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    padding: 0 1rem;
    transition: color 0.15s ease;
    ${({ labelOnInput }) =>
      labelOnInput &&
      `
      position: absolute;
      top: -1.4rem;
      left: 1rem;
    `};
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 2rem;
  margin-left: 4.5rem;
  cursor: pointer;

  a:hover {
    text-decoration: underline;
  }

  label,
  a {
    font-size: ${({ theme }) => theme.fontSize.s};
    cursor: pointer;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.8rem 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  resize: vertical;
`;

const Input = styled.input`
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  margin-top: 1px;
  margin-left: 1px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-left: 2rem;
  order: ${({ order }) => order};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.darkGray};
  border: 0;
  outline: none;
  background-color: rgba(255, 255, 255, 0) !important;

  &:-webkit-autofill {
    border-radius: 4px;
    box-shadow: 0 0 0 1000px white inset !important;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const FormButton = styled(Button)`
  margin-top: 3rem;

  ${media.greaterThan('tablet')`
    margin-left: ${({ landingButton }) => (landingButton ? '0' : '10px')};
    width: ${({ landingButton }) => (landingButton ? '100%' : '120px')};
    margin-top: ${({ flexDirection }) => !flexDirection && '0'};
  `}
`;

const HelpText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.mediumGray};
  text-align: center;
  margin-top: 2rem;
`;

const IconWrap = styled.div`
  position: relative;
  margin: 0 1.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShowPassword = styled.div`
  position: relative;
  cursor: pointer;
  float: right;
  margin: -0.6rem 1rem -0.4rem auto;
  width: auto;
  display: inline-block;
  opacity: 1;
  & svg {
    transition: opacity 0.15s ease;
  }
  & svg:last-child {
    position: absolute;
    left: 0;
    opacity: ${({ show }) => (show ? 1 : 0)};
  }
`;

const ErrorInput = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.mediumGray};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: 0.3rem;
  margin-bottom: 2rem;
`;

const ErrorCheckbox = styled.div`
  font-size: ${({ theme }) => theme.fontSize.s};
  color: ${({ theme }) => theme.colors.lightRed};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: 0.6rem;
  margin-left: 4.2rem;
`;

const renderButton = (button, direction, submit, loading) => {
  const { style, text, helpText } = button;
  const buttonProps = {
    landingButton: direction,
    buttonStyle: style,
    flexDirection: direction,
    onClick: submit,
    loading,
  };

  return (
    <>
      <FormButton {...buttonProps}>{text}</FormButton>
      <HelpText>{helpText}</HelpText>
    </>
  );
};

const renderTextArea = ({ label, placeholder, rows, required }, direction) => (
  <InputWrapper flexDirection={direction}>
    {label !== '' && <label>{label}</label>}
    <TextArea
      rows={rows}
      name='message'
      placeholder={placeholder}
      required={required}
    />
  </InputWrapper>
);

const renderCheckbox = (field, value, setValue, error, setError) => {
  const { text, link, name, errorCheckbox } = field;
  const onChange = () => {
    setValue(!value);
    error && setError(false);
  };
  const inputProps = {
    type: 'checkbox',
    name,
    id: name,
    checked: value,
    onChange,
  };
  return (
    <>
      <CheckboxWrapper>
        {text !== '' && (
          <Checkbox checked={value} onClick={onChange}>
            {text}
            {link && (
              <Link path={link.url} target='_blank'>
                {link.text}
              </Link>
            )}
          </Checkbox>
        )}
      </CheckboxWrapper>
      {error && errorCheckbox && <ErrorCheckbox>{errorCheckbox}</ErrorCheckbox>}
    </>
  );
};

// This password specific things could be done into a separate render e.g renderInputPassword
// but we are keeping on all the same input for now to simplify things
const RenderInput = ({
  field,
  direction,
  value,
  setValue,
  error,
  setError,
}) => {
  const {
    label,
    inputType,
    placeholder,
    required,
    errorInput,
    checkEmailEndpoint,
    errorCheckEmailEndpoint,
  } = field;
  const [showPassword, setShowPassword] = useState(false);
  const [validate, setValidate] = useState(false);
  const [validEmail, setValidEmail] = useState(true);

  const validateField = (val) => {
    const validationError = (result) => {
      setValidate(result);
      result && error && setError(false);
    };
    switch (inputType) {
      case 'password':
        validationError(testPassword(val));
        break;
      case 'email':
        validationError(testEmail(val));
        break;
      case 'phone':
        validationError(testPhone(val));
        break;
      default:
        return setValidate(val && !!val.length);
    }
  };

  const checkEmail = async () => {
    if (
      checkEmailEndpoint &&
      errorCheckEmailEndpoint &&
      value &&
      value.length
    ) {
      setValidEmail(true);
      const response = await request(checkEmailEndpoint, 'POST', {
        email: value,
      });
      const digResponse = JSON.parse(JSON.stringify(response)); // to handle ok 404 error responses
      if (!(digResponse.response && digResponse.response.status === 404)) {
        // email does exist
        setValidEmail(false);
      }
    }
  };

  // Execute check email in first render, just if in case email comes filled from localStorage
  useEffect(() => {
    checkEmail();
  }, []);

  const onChange = (e) => {
    setValue(e.target.value);
    validateField(e.target.value);
  };
  const inputProps = {
    type:
      inputType === 'password'
        ? showPassword
          ? 'text'
          : 'password'
        : inputType,
    name: inputType,
    required,
    placeholder,
    value,
    onChange,
    onFocus: checkEmail,
    onBlur: checkEmail,
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {inputType === 'password' && (
        <ShowPassword onClick={togglePassword} show={showPassword}>
          <ShowIcon />
          <HideIcon />
        </ShowPassword>
      )}
      <InputWrapper
        flexDirection={direction}
        labelOnInput={label && !!label.length}
        error={!validEmail || error}
      >
        <Input {...inputProps} />
        {['email', 'password', 'phone'].includes(inputType) && (
          <IconWrap>
            {validate && !error && validEmail && <CheckIcon width={20} />}
          </IconWrap>
        )}
        {label !== '' && <label>{label}</label>}
      </InputWrapper>
      {!validEmail && errorCheckEmailEndpoint ? (
        <ErrorInput>{errorCheckEmailEndpoint}</ErrorInput>
      ) : (
        error &&
        errorInput &&
        errorInput.length && <ErrorInput>{errorInput}</ErrorInput>
      )}
    </>
  );
};

const getModelName = (field) =>
  field && field.model ? field.model.name.replace(' Form', '') : null;

const withDraftData = (draftData, kind) => {
  // The comment code should be valid when we want make a signup A-B test
  // const signup_version = window.location.pathname.includes('-b') ? 'signup-one-step' : 'signup-four-steps'
  // if (signup_version === 'signup-four-steps') {
  //   return { ...draftData, signup_version }
  // } else {
  return (draftData = {
    // signup_version,
    address: '',
    country: 'es',
    kind,
    step: 3,
    tax_id: '',
    town: '',
    vat_country: '',
    zip: '',
    wants_all_in_one: false,
    ...draftData,
  });
};
// }
const clientSide = typeof window !== 'undefined';

const manageHardcodedData = (payload, link, kind) => {
  if (!clientSide) return;
  if (link.toLowerCase().includes('contact')) {
    window.localStorage.setItem('sign_up_draft_data', JSON.stringify(payload));
  } else if (link.includes('signup')) {
    const sign_up_draft_data = withDraftData(
      JSON.parse(window.localStorage.getItem('sign_up_draft_data')) ||
        payload.sign_up_draft_data,
      kind
    );
    window.localStorage.removeItem('sign_up_draft_data');
    return {
      ...payload,
      sign_up_draft_data: JSON.stringify(sign_up_draft_data),
    };
  }
  return payload;
};

const getValue = (modelName, fieldName) => {
  if (!clientSide) return;
  if (window.localStorage.getItem('sign_up_draft_data')) {
    const sign_up_draft_data =
      JSON.parse(window.localStorage.getItem('sign_up_draft_data')) ||
      payload.sign_up_draft_data;
    const field = sign_up_draft_data && sign_up_draft_data[fieldName];
    return field && field.length ? field : '';
  }
  return modelName === 'Checkbox' ? false : '';
};

const getInitialState = (fields) =>
  fields
    ? fields.reduce((prev, field) => {
        const modelName = getModelName(field);
        if (['Input', 'Checkbox', 'Text area'].includes(modelName)) {
          return {
            ...prev,
            [field.name]: {
              value: getValue(modelName, field.name),
              error: false,
            },
          };
        }
        return { ...prev };
      }, {})
    : null;
const reducer = (state, { field, value, error, resetState }) => {
  if (resetState) {
    return resetState;
  }
  return {
    ...state,
    [field]: {
      value: value !== undefined ? value : state[field]['value'],
      error: error !== undefined ? error : state[field]['error'],
    },
  };
};

const Form = (props) => {

  const {
    actionType,
    actionLink,
    redirectLink,
    fields,
    direction,
    kind,
    zapier,
  } = props;
  const [state, dispatch] = useReducer(reducer, getInitialState(fields));
  const [loading, setLoading] = useState(false);
  const [_, setCookie] = useCookies([]);

  const setReferrals = () => {
    if (typeof window !== 'undefined') {
      const params = new URL(document.location).searchParams;
      const affValue = params.get('aff');
      const giftCardValue = params.get('gift_card');
      const currentDate = new Date();
      const expires = new Date(
        currentDate.setMonth(currentDate.getMonth() + 1)
      );
      const hostname = window.location.hostname;
      const domain = hostname === 'localhost' ? hostname : `.${hostname}`;
      const cookiesConfig = { expires, domain, path: '/' };

      if (!!affValue) {
        setCookie(COOKIE_AFF, affValue, cookiesConfig);
        setCookie(COOKIE_GIFT_CARD, giftCardValue, cookiesConfig);
      }
    }
  };

  useEffect(() => {
    dispatch({ resetState: getInitialState(fields) });
    setReferrals();
  }, [fields]);

  const validateFields = () => {
    let passes = true;
    Object.keys(state).forEach((key) => {
      const field = fields.find((f) => f.name === key);
      const { required, name } = field;
      if (required) {
        const dispatchError = (isError = true) =>
          dispatch({ field: name, error: isError });
        const { value } = state[name];
        switch (getModelName(field)) {
          case 'Input':
            if (
              (name === 'email' && !testEmail(value)) ||
              (name === 'password' && !testPassword(value)) ||
              (name === 'phone' && !testPhone(value)) ||
              !value ||
              (value && value.length === 0)
            ) {
              dispatchError();
              passes = false;
            } else {
              dispatchError(false);
            }
            break;
          case 'Checkbox':
            if (!value) {
              dispatchError();
              passes = false;
            } else {
              dispatchError(false);
            }
            break;
          default:
            break;
        }
      }
    });
    return passes;
  };

  const submit = async () => {
    if (!clientSide) return;
    if (loading) {
      return;
    }
    if (validateFields()) {
      setLoading(true);
      let payload = fields.reduce((prev, field) => {
        const modelName = getModelName(field);
        if (modelName.includes('Additional parameter')) {
          const { name, value } = field;
          return { ...prev, [name]: value };
        } else if (
          field &&
          !field.includeInPayload &&
          !field.includeInSignUpDraftData
        ) {
          return prev;
        } else if (
          field.name &&
          field.includeInPayload &&
          field.includeInSignUpDraftData
        ) {
          return {
            ...prev,
            [field.name]: state[field.name].value,
            sign_up_draft_data: {
              ...prev.sign_up_draft_data,
              [field.name]: state[field.name].value,
            },
          };
        } else if (
          field.name &&
          !field.includeInPayload &&
          field.includeInSignUpDraftData
        ) {
          return {
            ...prev,
            sign_up_draft_data: {
              ...prev.sign_up_draft_data,
              [field.name]: state[field.name].value,
            },
          };
        } else if (
          field.name &&
          field.includeInPayload &&
          !field.includeInSignUpDraftData
        ) {
          return {
            ...prev,
            [field.name]: state[field.name].value,
            sign_up_draft_data: { ...prev.sign_up_draft_data },
          };
        }
        return prev;
      }, {});
      payload = manageHardcodedData(payload, actionLink, kind);
      if (zapier) {
        try {
          await requestToZapier(actionLink, actionType, payload);
          if (redirectLink && redirectLink.length) {
            window.location = redirectLink;
          }
        } catch (e) {
          console.log('error');
          console.error(JSON.parse(JSON.stringify(e)));
        } finally {
          setLoading(false);
        }
      } else {
        try {
          const response = await request(actionLink, actionType, payload);
          if (response && response.data && response.data.redirectUrl) {
            window.location = response.data.redirectUrl
          }
          if (redirectLink && redirectLink.length) {
            window.location = redirectLink;
          }
        } catch (e) {
          console.log('error');
          console.error(JSON.parse(JSON.stringify(e)));
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const renderFormField = (field, direction) => {
    const modelName = getModelName(field);
    const { value, error } = state[field.name] || { value: null, error: null };
    const setValue = (value) => dispatch({ field: field.name, value });
    const setError = (error) => dispatch({ field: field.name, error });
    switch (modelName) {
      case 'Input':
        const renderInputProps = {
          field,
          direction,
          value: value || '',
          setValue,
          error,
          setError,
        };
        return <RenderInput {...renderInputProps} />;
      case 'Checkbox':
        return renderCheckbox(field, value, setValue, error, setError);
      case 'Submit button':
        return renderButton(field.button, direction, submit, loading);
      case 'Text area':
        return renderTextArea(field, direction);
      case 'Additional parameter':
        break;
      default:
        console.warn('There is no case for this type of field: ' + modelName);
        break;
    }
  };

  return (
    <StyledForm method='post' flexDirection={direction}>
      {fields &&
        fields.map((field, i) => (
          <Fragment key={i}>{renderFormField(field, direction)}</Fragment>
        ))}
    </StyledForm>
  );
};

export default Form;
