import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: ${ ({ maxWidth }) => maxWidth };
  min-height: ${ ({ height }) => height };
  margin: 1rem auto;
  background-color: ${ ({ theme }) => theme.colors.white };
  box-shadow: ${ ({ theme }) => theme.shadow.card };
  border-radius: 4px;
  padding: 4rem 3rem;
  width: 90%;
  text-align: left;
  position: relative;
  margin-top: 6rem;
`

const ContentWrap = styled.div`
  margin: ${ ({ margin }) => margin || '0' };
  padding: ${ ({ padding }) => padding || '0' };
`

const Author = styled.p`
  margin-bottom: ${ ({ marginBottom }) => marginBottom || '0' };
  font-size: ${ ({ theme, fontSize }) => theme.fontSize[fontSize || 'l'] };
  font-weight: ${ ({ theme, fontWeight }) => theme.fontWeight[fontWeight || 'regular'] };
  color: ${ ({ theme, color }) => theme.colors[color || 'black'] };
`

const JobPosition = styled.h3`
  margin-bottom: ${ ({ marginBottom }) => marginBottom || '1.5rem' };
  color: ${ ({ theme, color }) => theme.colors[color || 'black'] };
  font-size: ${ ({ theme, fontSize }) => theme.fontSize[fontSize || 'm'] };
  font-weight: ${ ({ theme, fontWeight }) => theme.fontWeight[fontWeight || 'regular'] };
`

const Text = styled.h4`
  font-size: ${ ({ theme, fontSize }) => theme.fontSize[fontSize || 'm'] };
  font-style: ${ ({ italic }) => italic && 'italic' };
  color: ${ ({ theme, color }) => theme.colors[color || 'black'] };
  line-height: ${ ({ theme, lineHeight }) => theme.lineHeight[lineHeight || 'default'] };
`

const Bullets = styled.div`
	display: flex;
	justify-content: center;
  margin-bottom: 3rem;
`

const Bullet = styled.span`
	cursor: pointer;
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: ${ ({ active, theme }) => active ? theme.colors.black : theme.colors.lightGray };
	transition: background-color .25s ease-in-out;
	margin: .5rem .5rem 0;
`

export {
  Wrapper,
  ContentWrap,
  Author,
  JobPosition,
  Text,
  Bullets,
  Bullet
};
