import React from 'react';
import styled from 'styled-components';
import { media } from '../../../style'
import { Link, List, ListItem } from '../../ui'
import LogoSVG from '../../../assets/svg/logo.svg'

const Title = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.darkGreen};
  line-height: ${({ theme }) => theme.lineHeight.super};
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  margin: 3rem 0 1rem;
`;

const LogoWrapper = styled.div`
  margin: 4rem 0;

  ${ media.greaterThan('desktop')`
    margin: 1.8rem 0 3rem;
  `};
`

const Icon = styled.img`
  margin-right: .5rem;
`

const Address = styled.span`
  position: relative;
  max-width: 40%;

  ${ ({ first, theme }) => first && `
    margin-right: 1.5rem;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -0.7rem;
      width: 1px;
      height: 1.8rem;
      background-color: ${ theme.colors.mediumGray };
    }
  `}
`

const AddressText = styled.span`
  font-weight: ${ ({ theme }) => theme.fontWeight.regular };
  font-size: ${ ({ theme }) => theme.fontSize.s };
`

const PhoneText = styled.span`
  font-size: ${ ({ theme }) => theme.fontSize.s };
`

const Intro = styled.span`
  line-height: ${ ({ theme }) => theme.lineHeight.double };
  font-size: ${ ({ theme }) => theme.fontSize.m };
`

const targetLink = newTab => newTab ? '_blank' : '_self'

const renderAddress = ({ text,  url, icon, openInNewTab }, i) => (
  <Address key={ i } first={ i === 0 }>
    { icon && <Icon src={ icon.url } /> }
    <Link path={ url } target={ targetLink(openInNewTab) }>
      { i === 0
        ? <AddressText>{ text }</AddressText>
        : <PhoneText>{ text }</PhoneText>
      }
    </Link>
  </Address>
);

const FooterSection = props => {
  const { section: { title, links, description, addressOne, addressTwo }, firstSection } = props
  return (
    <List>
      { firstSection && <ListItem><LogoWrapper><LogoSVG /></LogoWrapper></ListItem> }
      { title && <ListItem><Title>{ title }</Title></ListItem> }
      { description && <ListItem maxWidth='70%'><Intro>{ description }</Intro></ListItem> }
      { addressOne &&  
        <ListItem footer maxWidth='80%'>
          { addressOne.map((link, i) => renderAddress(link, i)) }
        </ListItem>
      }
      { addressTwo &&  
        <ListItem footer maxWidth='80%'>
          { addressTwo.map((link, i) => renderAddress(link, i)) }
        </ListItem>
      }
      { links.map(({ text, url, icon, openInNewTab }, i) => {
        return (
          <ListItem footer key={ i }>
            <Link path={ url } target={ targetLink(openInNewTab) } footer>
              { icon && <Icon src={ icon.url } /> }
              { text }
            </Link>
          </ListItem>
        )
      }
      )}
    </List>
  )
}

export default FooterSection;