import React from 'react'
import styled from 'styled-components'
import { media } from '../../../style'

const Wrapper = styled.div`
  max-width: 932px;
  margin: 2.8rem auto 1.8rem;
  width: 100%;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.paleTeal};
  color: ${({ theme }) => theme.colors.teal};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  border-radius: 3px;
  text-align: center;
  ${media.lessThan('desktop')`
    width: auto;
    margin: 2.8rem 2.8rem 1.8rem;
  `}
  ${media.lessThan('mobile')`
    position: relative;
    top: 0;
    left: calc(50% - 150px);
    width: 300px;
    margin: 0;
  `}
`;

const AnchorLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
`

const handleAnchor = () => window.scroll({
  top: 1000,
  behavior: 'smooth'
})

const Banner = ({ data }) => (
  data.map(({ text, anchorLink }, index) => (
    <Wrapper key={ index }>
      { text && <span>{ text }</span> }
      { anchorLink && <AnchorLink onClick={ handleAnchor }>{ anchorLink.text }</AnchorLink> }
    </Wrapper>
  ))
)

export default Banner