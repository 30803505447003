import React, { useState } from 'react';
import styled from 'styled-components';
import CancelSVG from '../../assets/svg/cancel.svg';

const Wrap = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: center;
  padding: 50px 3rem 5px;
  // TODO: Fix the harcoded bgColor
  background-color: #eef7e5;
`;

const InputWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
`;

const SearchInput = styled.input`
  height: 50px;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.sm};
  padding-left: 2rem;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.darkGray};
  border: 0;
  border-radius: 4px 4px 0 0;
  outline: none;

  &:-webkit-autofill {
    border-radius: 4px;
    box-shadow: 0 0 0 1000px white inset !important;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.mediumGray};
  }
`;

const Cancel = styled(CancelSVG)`
  position: absolute;
  right: 6px;
  top: 15px;
  transform: scale(0.8);
  cursor: pointer;

  line {
    transition: stroke 0.35s ease;
  }

  &:hover {
    line {
      stroke: ${({ theme }) => theme.colors.black};
    }
  }
`;

const SearchResults = styled.ul`
  position: absolute;
  width: 100%;
  max-height: 290px;
  margin-top: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadow.cta};
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  overflow-y: scroll;
`;

const Result = styled.a`
  display: block;
  text-decoration: none;
  padding: 2rem 2rem;
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme }) => theme.colors.mediumGray};
  transition: all 0.35s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.paleBlue};
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Search = (props) => {
  const { pages } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [showResults, setShowResults] = useState(false);

  const handleCancelSearch = () => {
    setShowResults(false);
    setSearchTerm('');
  };

  const handleChange = (event) => {
    const { value } = event.target;

    setShowResults(value.length > 2);
    setSearchTerm(value);
  };

  const searchResults = pages.filter(({ searchKeywords }) =>
    searchKeywords.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const searchInputProps = {
    type: 'text',
    placeholder: 'Buscar respuestas...',
    value: searchTerm,
    onChange: handleChange,
  };

  const getFullUrl = (urlSlug) =>
    `https://getquipu.com/es${urlSlug === '/' ? '' : `/${urlSlug}`}`;

  return (
    <Wrap>
      <InputWrap>
        <SearchInput {...searchInputProps} />
        {showResults && <Cancel onClick={handleCancelSearch} />}
        {showResults && (
          <SearchResults>
            {searchResults.map(({ urlSlug, title }, index) => (
              <li key={index}>
                <Result href={getFullUrl(urlSlug)}>{title}</Result>
              </li>
            ))}
          </SearchResults>
        )}
      </InputWrap>
    </Wrap>
  );
};

export default Search;
