import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../style';
import isIncluded from '../../helpers/isIncluded';
import {
  Title,
  Description,
  Carousel,
  Image,
  Paragraph,
  Link,
  CallToAction,
  VideoAutoplay,
} from '../ui';
import {
  PromoCard,
  PromoApp,
  PricingCard,
  FullWidthCard,
  TeamMember,
  JobCard,
  Form,
  FeaturedContent,
  Faqs,
  Table,
  Cards,
  FormattedText,
  Banner,
  WebinarCard,
  KnowledgeCard,
  KnowledgeArticle,
  CarouselCard,
  SegmentCard,
  VideoCard
} from './ModularContent';

const whatPadding = (padding, { sizing }) => {
  const { defaultPadding, paddingLeft, paddingRight, noPadding } = sizing;
  switch (padding) {
    case 'default-padding':
      return defaultPadding;
    case 'padding-left':
      return paddingLeft;
    case 'padding-right':
      return paddingRight;
    case 'no-padding':
      return noPadding;
    default:
      return defaultPadding;
  }
};

const whatMargin = (maxWidth, gridOrder, { sizing }) => {
  const { marginLeftAuto, marginRightAuto } = sizing;
  if (gridOrder) {
    return maxWidth && marginLeftAuto;
  }
  if (!gridOrder) {
    return maxWidth && marginRightAuto;
  }
};

const Wrap = styled.div`
  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
  text-align: center;
  padding: ${({ paddingOptions, theme }) => whatPadding(paddingOptions, theme)};
  ${({ hiddenOverflow }) => (hiddenOverflow ? 'overflow: hidden;' : '')}
  margin: 0;
  align-self: ${({ alignSelf }) => alignSelf};
  ${({ justifySelf }) =>
    justifySelf !== 'unset'
      ? `
		justify-self: ${justifySelf};
		margin: unset;
	`
      : ''};
  ${media.greaterThan('tablet')`
		flex-direction: ${({ flexDirection }) => flexDirection};
		max-width: ${({ maxWidth, theme }) => maxWidth && theme.sizing.maxItemWidth};
		grid-row-start: ${({ gridOrder }) => gridOrder};
		text-align: ${({ textAlign }) => textAlign};
		padding-left: ${({ gridOrder, maxWidth }) => !gridOrder && maxWidth && '7rem'};
		padding-right: ${({ gridOrder, maxWidth }) =>
      gridOrder && maxWidth ? '7rem' : null};
		margin: ${({ maxWidth, gridOrder, theme }) =>
      whatMargin(maxWidth, gridOrder, theme)};
		display: flex;
	`}
  ${media.greaterThan('desktop')`
		overflow: initial;
	`}
`;

const ButtonsWrap = styled.div`
  ${({ moreThanOne }) =>
    moreThanOne
      ? 'display: flex; flex-direction: column; align-items: center; '
      : ''};
  * {
    vertical-align: middle;
  }
  > :not(:first-child) {
    margin-top: 2rem;
  }
  ${media.greaterThan('tablet')`
		${({ moreThanOne }) => (moreThanOne ? 'align-items: flex-start; margin-left: 2rem;' : 'margin-top: 2rem')}
	`}
  ${media.greaterThan('largeDesktop')`
		${({ moreThanOne }) =>
      moreThanOne ? 'flex-direction: row; align-items: center; margin-left: 2rem;' : ''}
		> :not(:first-child) {
			margin-top: 0;
			margin-left: 2rem;
		}
	`}
`;

const renderButtons = (buttons, onClick) => (
  <ButtonsWrap moreThanOne={buttons.length > 1}>
    {buttons.map((cta, i) => {
      const linkCtaProps = {
        path: cta.url,
        onClick,
        ...cta,
      };
      return <CallToAction key={i} {...linkCtaProps} />;
    })}
  </ButtonsWrap>
);

const ToggleFormWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 2rem;
`;

const ToggleForm = styled.span`
  cursor: pointer;
  display: flex;
  span {
    display: inline-block;
    border-radius: 10px;
  }
  > span {
    width: 20px;
    height: 20px;
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    margin-right: 5px;
    > span {
      width: 12px;
      height: 12px;
      background-color: ${({ theme, active }) =>
        active ? theme.colors.teal : 'transparent'};
      margin-left: 3px;
      margin-top: 3px;
      transition: background-color 0.25s ease;
    }
  }
`;

const Item = (props) => {
  const {
    content,
    order,
    justifyContent,
    alignSelf,
    justifySelf,
    flexDirection,
    slug,
    paddingOptions,
    textAlign,
    maxWidth,
    hideOnMobile,
    onClick,
    isRegister,
  } = props;
  const wrapProps = {
    gridOrder: order,
    justifyContent,
    alignSelf,
    justifySelf,
    flexDirection,
    paddingOptions,
    textAlign,
    hiddenOverflow: content.length > 0 && content[0].review,
    maxWidth,
    hideOnMobile,
  };
  return (
    <Wrap {...wrapProps}>
      {content.map((field, i) => {
        const {
          title,
          pageTitle,
          titleColor,
          description,
          textAlign,
          maxWidth,
          content,
          review,
          reviewType,
          callToAction,
          image,
          secondImage,
          alt,
          caption,
          regularLink,
          toggleFormOne,
          toggleFormTwo,
          forms,
          members,
          formattedText,
          link,
          cards,
          tableModel,
          pricingCard,
          fullWidthCard,
          featuredContent,
          promoCard,
          promoApp,
          jobCard,
          faqs,
          banner,
          webinarCard,
          knowledgeArticle,
          knowledgeCard,
          hideImageMobile,
          video,
          backgroundImage,
          autoplay,
          lateralScroll,
          carouselCard,
          calltoaction,
          segmentCard,
          videoCard,
		  animatedPlayButton,
		  videoLink,
        } = field;
        const imgProps = {
          image,
          alt,
          caption,
          paddingOptions,
		  animatedPlayButton,
		  videoLink,
          isLegalPage: isIncluded(slug, 'legal'),
          regularLink,
          secondImage,
          hideOnMobile,
          isRegister,
          onClick,
        };
        const videoProps = {
          video,
          backgroundImage,
          hideOnMobile,
          autoplay,
        };
        const titleProps = {
          itemTitle: true,
          pageTitle,
          color: titleColor && titleColor.colorhex.hex,
        };
        const descriptionProps = { textAlign, maxWidth };
        const linkProps = link && { path: link.url, children: link.text };
        const [formType, setFormType] = useState(0);
        const formProps = forms && {
          title: forms[formType].title,
          actionLink: forms[formType].actionLink,
          actionType: forms[formType].actionType,
          redirectLink: forms[formType].redirectLink,
          fields: forms[formType].formModularContent,
          direction: forms[formType].direction,
          zapier: forms[formType].zapier,
          kind: formType === 0 ? 'freelance' : 'organization',
        };
        const toggleForm = (i) => () => setFormType(i);

        return (
          <Fragment key={i}>
            {banner && <Banner data={banner} />}
            {knowledgeCard && <KnowledgeCard data={knowledgeCard} />}
            {knowledgeArticle && <KnowledgeArticle data={knowledgeArticle} />}
            {title && <Title {...titleProps}>{title}</Title>}
            {description && (
              <Description {...descriptionProps}>{description}</Description>
            )}
            {content && <Paragraph>{content}</Paragraph>}
            {formattedText && <FormattedText>{formattedText}</FormattedText>}
            {image && alt && <Image {...imgProps} />}
            {video && <VideoAutoplay {...videoProps} />}
            {members && <TeamMember {...members} />}
            {callToAction && renderButtons(callToAction, onClick)}
            {review && <Carousel data={review} type={reviewType} />}
            {forms && (
              <>
                {forms.length > 1 && (
                  <ToggleFormWrap>
                    {[toggleFormOne, toggleFormTwo].map((toggle, i) => (
                      <ToggleForm
                        key={i}
                        onClick={toggleForm(i)}
                        active={formType === i}
                      >
                        <span>
                          <span></span>
                        </span>
                        <FormattedText>{toggle}</FormattedText>
                      </ToggleForm>
                    ))}
                  </ToggleFormWrap>
                )}
                <Form {...formProps} />
              </>
            )}
            {link && <Link {...linkProps} />}
            {cards && <Cards cards={cards} hideImageMobile={hideImageMobile} />}
            {tableModel && <Table data={tableModel} />}
            {pricingCard && <PricingCard data={pricingCard} lateralScroll={lateralScroll}/>}
            {fullWidthCard && <FullWidthCard data={fullWidthCard} />}
            {featuredContent && <FeaturedContent data={featuredContent} />}
            {promoCard && <PromoCard {...promoCard} />}
            {promoApp && <PromoApp {...promoApp} />}
            {carouselCard && <CarouselCard data={carouselCard} calltoaction={calltoaction}/>}
            {segmentCard && <SegmentCard data={segmentCard} />}
            {jobCard && <JobCard data={jobCard} />}
            {faqs && <Faqs data={faqs} />}
            {webinarCard && <WebinarCard data={webinarCard} />}
            {videoCard && <VideoCard data={videoCard}/>}
          </Fragment>
        );
      })}
    </Wrap>
  );
};

export default Item;