import React from 'react';
import styled from 'styled-components';
import { media } from '../../style';

const SectionSub = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};
  text-align: ${({ textAlign }) => (textAlign === 'none' ? 'left' : 'center')};

  ${media.greaterThan('tablet')`
      font-size: ${({ theme }) => theme.fontSize.l};
        margin-bottom: 2rem;
    `}
`;

const Subtitle = ({ children, textAlign, color }) => (
  <SectionSub textAlign={textAlign} color={color}>
    {children}
  </SectionSub>
);

export default Subtitle;
