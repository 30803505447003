import React from 'react';
import styled from 'styled-components';
import { media } from '../../../style';

const Wrap = styled.article`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  
  ${ media.greaterThan('tablet')`
    padding: 0;
  `}
`

const Title = styled.h5`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: 0.6rem 0;
`;

const Text = styled.p`
  font-size: ${ ({ theme }) => theme.fontSize.s };
  margin-top: .25rem;
`

const Img = styled.img`
  max-width: 100%;
`

const SocialIconsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 1rem auto;
`

const Icon = styled.img`
  padding: 0 .5rem;
  cursor: pointer;
`

const TeamMember = ({ fullName, jobTitle, social, picture: { url } }) => (
  <Wrap itemScope itemType='http://schema.org/Person' >
    <Img src={ url } alt={ fullName } itemProp='image' />
    <Title itemProp='name'>{ fullName }</Title>
    <Text itemProp='jobTitle'>{ jobTitle }</Text>
    <SocialIconsWrap>
      { 
        social.map(({ url, icon: { icon } }, index) => 
          <a itemProp='sameAs' key={ index } href={ url }>
            <Icon src={ icon.url } alt={ icon.name } />
          </a>) 
      }
    </SocialIconsWrap>
  </Wrap>
)

export default TeamMember;
