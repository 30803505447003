import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import useInterval from '../../../helpers/useInterval';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto 3rem;

  ${media.greaterThan('tablet')`
    padding: 0 4rem;
    flex-wrap: nowrap;
  `}
`;

const CardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  width: 220px;
  margin: 0 auto;

  ${media.lessThan('tablet')`
    width: 300px;
  `}
  ${media.greaterThan('largeDesktop')`
    margin-left: 0;
    margin-right: 0;
    flex-shrink: 0;
    width: 300px;
  `}
`;

const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  cursor: ${({ active }) => !active && 'pointer'};
  border-radius: 8px;
  margin: 1rem auto;
  border: ${({ active, theme }) => active && `2px solid ${theme.colors.gold}`};
  transform: ${({ active }) => active && 'scale(1.1)'};
  transform-origin: center;
  position: ${({ active }) => active && 'relative'};
  padding: ${({ active }) => (active ? '1rem .5rem' : '.5rem 1.5rem')};
  height: ${({ active }) => active && '200px'};
  transition: transform 0.5s ease;

  ${media.greaterThan('desktop')`
    transform-origin: right;
    border-color: ${({ theme }) => theme.colors.teal};
  `}
`;

const BaseText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.darkGray};
  transform: ${({ active }) => active && 'scale(.9)'};

  ${media.greaterThan('desktop')`
    text-align: left;
  `}
`;

const CardTitle = styled(BaseText)`
  font-size: ${({ active, theme }) => active && theme.fontSize.m};
  font-weight: ${({ active, theme }) => active && theme.fontWeight.medium};
  padding: ${({ active }) => (active ? '.5rem 0 1rem' : '.5rem 0')};
`;

const CardContent = styled(BaseText)`
  display: ${({ active }) => (active ? 'block' : 'none')};
  line-height: ${({ theme }) => theme.lineHeight.medium};
`;

const ImageWrapper = styled.div`
  display: none;

  ${media.greaterThan('desktop')`
    display: flex;
    position: relative;
    left: -10px;
    flex-shrink: 1;
    max-width: 1000px;
  `}
`;

const ImageWrapperMobile = styled.div`
  display: flex;
  position: relative;
  flex-shrink: 1;
  max-width: 1000px;
  border: 2px solid #f1f2f3;
  border-radius: 4px;
  box-shadow: 0 0 26px rgba(0, 0, 0, 0.06);
  margin-top: 2rem;

  ${media.greaterThan('desktop')`
    left: -10px;
    margin-top:0;
  `}
`;

const Image = styled.img`
  opacity: ${({ active }) => (active ? '1' : '0')};
  height: ${({ active }) => (active ? '100%' : '0')};
  width: ${({ active }) => (active ? '100%' : '0')};
  transition: opacity 0.35s ease-in-out;
`;

const Video = styled.video`
  opacity: ${({ active }) => (active ? '1' : '0')};
  height: ${({ active }) => (active ? '100%' : '0')};
  width: ${({ active }) => (active ? '100%' : '0')};
  transition: opacity 0.35s ease-in-out;
`;

const Cards = (props) => {
  const { cards, hideImageMobile } = props;
  const images = cards
    .map(({ cardImage }) => cardImage)
    .filter((image) => !!image);
  const videos = cards
    .map(({ cardVideo }) => cardVideo)
    .filter((video) => !!video);

  const [active, setActive] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  useInterval(
    () => {
      setActive((active + 1) % cards.length);
    },
    isRunning ? 5000 : null
  );

  const goTo = (index) => () => setActive(index);
  const getVisualProps = (visual, i) => {
    return {
      onMouseEnter: () => setIsRunning(false),
      onMouseLeave: () => setIsRunning(true),
      key: i,
      active: active === i,
      src: visual && visual.url,
      className: 'lazyload',
    };
  };
  const showImages = () =>
    !!images &&
    images.map((image, i) => <Image key={i} {...getVisualProps(image, i)} />);
  const showVideos = () =>
    !!videos &&
    videos.map((video, i) => (
      <Video
        key={i}
        autoPlay={true}
        muted
        loop
        playsInline
        {...getVisualProps(video, i)}
      />
    ));
  return (
    <Wrapper>
      <CardWrapper>
        {cards.map(({ cardTitle, cardContent }, i) => {
          let isActive = active === i;
          return (
            <Fragment key={i}>
              <Card
                active={isActive}
                onClick={goTo(i)}
                onMouseEnter={() => setIsRunning(false)}
                onMouseLeave={() => setIsRunning(true)}
              >
                <CardTitle active={isActive}>{cardTitle}</CardTitle>
                <CardContent active={isActive}>{cardContent}</CardContent>
              </Card>
            </Fragment>
          );
        })}
      </CardWrapper>
      {!hideImageMobile ? (
        <ImageWrapperMobile>
          {showImages()}
          {showVideos()}
        </ImageWrapperMobile>
      ) : (
        <ImageWrapper>
          {showImages()}
          {showVideos()}
        </ImageWrapper>
      )}
    </Wrapper>
  );
};

export default Cards;