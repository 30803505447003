import React, { useState } from 'react'
import styled from 'styled-components'
import { media } from '../../style'
import LanguageIcon from '../../assets/svg/language.svg'
import { ListItem, Link } from './';

const SelectorWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  align-items: flex-start;

  ${ media.greaterThan('desktop')`
    margin: 0;
    width: 135px;
    justify-content: flex-start;
  `}
`

const Selector = styled.ul`
  height: ${ ({ isExpanded }) => isExpanded ? '180px' : '25px' };
  overflow-y: hidden;
  padding-top: 4px;
  transition: height .25s ease;
  width: 80px;
  ${ media.greaterThan('desktop')`
    left: 10px;
    width: 130px;
    position: absolute;
    bottom: 0;
    padding: ${ ({ isExpanded }) => isExpanded ? '3rem 2rem' : '0 2rem' };
    height: ${ ({ isExpanded }) => isExpanded ? '205px' : '25px' };
    border-radius: 4px;
    background-color: ${ ({ bgColor }) => bgColor };
  `}

  li:first-child {
    padding-bottom: 1.5rem;
  }

  li:not(:first-child) {
    padding: 1.5rem 0;
  }

  a {
    cursor: pointer;
  }
`

const Arrow = styled.span`
  cursor: pointer;
  position: absolute;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-color: ${ ({ theme }) => theme.colors.white };
  border-radius: 2px;
  width: 12px;
  height: 12px;
  left: 100%;
  top: ${ ({ isExpanded }) => !isExpanded ? '6px' : '12px' };
  transform: ${ ({ isExpanded }) => !isExpanded ? 'rotate(45deg)' : 'rotate(-135deg)' };

  ${ media.greaterThan('desktop')`
    left: 120px;
  `}
`

const CollapseSelector = props => {
  const { languages, bgColor, locale } = props
  const [expandSelector, setExpandSelector] = useState(false)
  const schemaURL = 'http://schema.org'
  const toggleSelector = () => setExpandSelector(!expandSelector)
  const firstLang = languages.filter(({ url }) => locale !== 'es' ? url === `/${ locale }`: url === '/' );
  const sortedLangs = languages.filter(({ text }) => text !== firstLang[0].text);
  sortedLangs.unshift(firstLang[0]);

  return (
    <SelectorWrapper onClick={ toggleSelector } itemScope itemProp='Organization' itemType={ `${ schemaURL }/Organization` }>
      <LanguageIcon style={{ marginRight: '.5rem', zIndex: '1' }} />
      <Selector isExpanded={ expandSelector } bgColor={ bgColor } >
        { sortedLangs.map(({ text, url }, i) => (
          <ListItem key={ i } itemScope itemProp='availableLanguage' itemType={ `${ schemaURL }/Language` } >
            <Link path={ expandSelector ? null : url } target={ null } langLink itemProp='sameAs'>
              <span>{ text }</span>
            </Link>
          </ListItem>
        ))}
      </Selector>
      <Arrow isExpanded={ expandSelector } />
    </SelectorWrapper>
  )
}

export default CollapseSelector