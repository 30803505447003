import React from 'react'
import styled from 'styled-components'
import { media } from '../../style'
import { Container, Link } from '../ui'

const PostWrapper = styled(Container)`
  display: flex;
  flex-direction: column;

  ${ media.greaterThan('tablet')`
    flex-direction: row;
    justify-content: space-evenly;
  `}
`

const PostCard = styled.article`
  background-color: ${ ({ theme }) => theme.colors.white };
  border-radius: 10px;
  box-shadow: ${ ({ theme }) => theme.shadow.postCard };
  display: flex;
  padding: 1.5rem;
  margin: 1rem 1.5rem;

  ${ media.greaterThan('tablet')`
    padding: 0;
    flex-direction: column;
    max-width: 370px;
    flex-shrink: 1;
  `}
`

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  border-radius: 2px;

  ${ media.greaterThan('tablet')`
    height: 150px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-image: ${ ({ bgImage }) => `url(${ bgImage })` };
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  `}
`

const Image = styled.img`
  width: auto;
  height: 100%;

  ${ media.greaterThan('tablet')`
    display: none;
  `}
`

const PostText = styled.div`
  text-align: left;
  margin-left: 2rem;

  ${ media.greaterThan('tablet')`
    text-align: center;
    padding: 2rem 3rem;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  `}
`

const PostTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  padding-top: 0.5rem;

  ${media.greaterThan('tablet')`
    padding: 2.5rem 0;
    font-size: ${({ theme }) => theme.fontSize.l};
  `}
`;

const PostCategory = styled.p`
  display: none;
  font-size: ${ ({ theme }) => theme.fontSize.sm };
  font-weight: ${ ({ theme }) => theme.fontWeight.medium };
  color: ${ ({ theme }) => theme.colors.mediumGray };
  text-transform: uppercase;

  ${ media.greaterThan('tablet')`
    display: block;
  `}
`

const PostSnippet = styled.p`
  display: none;
  font-size: ${ ({ theme }) => theme.fontSize.s };
  font-weight: ${ ({ theme }) => theme.fontWeight.regular };
  color: ${ ({ theme }) => theme.colors.mediumGray };
  line-height: ${ ({ theme }) => theme.lineHeight.double };
  max-height: 50px;
  overflow: hidden;
  position: relative;

  ${ media.greaterThan('tablet')`
    display: block;
  `}
`

const ReadMore = styled.div`
  display: inline-block;
  margin: 1.5rem auto 0;
  color: ${ ({ theme }) => theme.colors.teal };
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 0;
    transition: background-color .25s ease;
  }

  &:hover {
    &::after {
      background-color: currentColor;
    }
  }
`

const BlogSection = ({ blogFeed }) => {
  const posts = blogFeed.map(post => {
    return {
      title: post.node.title,
      url: post.node.url,
      image: new DOMParser().parseFromString(post.node.content, 'text/html').images[0].src,
      category: post.node.categories[0],
      snippet: post.node.contentSnippet
    }
  })

  return (
    <PostWrapper>
      { posts.map(({ title, url, image, category, snippet }, i) => i < 3 && (
        <PostCard key={ i }>
          <ImageWrapper bgImage={ image }>
            <Image data-src={ image } alt={ title } className='lazyload' />
          </ImageWrapper>
          <PostText>
            <PostCategory>{ category }</PostCategory>
            <PostTitle>{ title }</PostTitle>
            <PostSnippet>{ snippet }</PostSnippet>
            <ReadMore>
              <Link path={ url } target='_blank' >Leer más</Link>
            </ReadMore>
          </PostText>
        </PostCard>
      ))}
    </PostWrapper>
  )
}

export default BlogSection
