import React from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import { CallToAction, FeatureItemChecks } from '../../ui';
import DurationSVG from '../../../assets/svg/duration.svg';
import CalendarSVG from '../../../assets/svg/calendar.svg';

const CARD_WIDTH = 280;

const Wrapper = styled.div`
  max-width: 1340px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${media.greaterThan('tablet')`
    flex-direction: row;
  `};
`;
const DurationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  & p {
    padding: 0 0.5rem;

    ${media.greaterThan('tablet')`
    padding: 0 1rem;
  `};
  }
`;
const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 2rem 1rem;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.shadow.pricingCard};
  width: ${() => `${CARD_WIDTH}px`};
  display: flex;
  flex-direction: column;

  ${media.greaterThan('mobile')`
    width: 85%;
  `}

  ${media.greaterThan('tablet')`
    max-width: 450px;
    margin: 0 1rem;
  `}
`;
const Title = styled.h2`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: 1rem 0;

  ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.l};
  `}
`;
const ContentWrapper = styled.div`
  padding: 1rem 2rem;
`;
const Image = styled.img`
  margin: 0;
  max-width: 100%;
`;
const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 2.5rem;
  margin: 1rem 0;
`;

const WebinarCard = (props) => {
  const { data } = props;
  return (
    <Wrapper>
      {data.map((card, i) => {
        const {
          title,
          duration,
          description,
          date,
          image,
          calltoaction,
          features,
        } = card;
        return (
          <Card key={i}>
            {image && (
              <Image
                data-src={image.url}
                alt={image.alt}
                className="lazyload"
              />
            )}
            <ContentWrapper>
              <Title>{title}</Title>
              <DurationWrapper>
                <DurationSVG />
                <p>{duration}</p>
                <CalendarSVG />
                <p>{date}</p>
              </DurationWrapper>
              <CallToAction path={calltoaction.url} {...calltoaction} />
              <Description>{description}</Description>
              <FeatureItemChecks data={features} />
            </ContentWrapper>
          </Card>
        );
      })}
    </Wrapper>
  );
};

export default WebinarCard;
