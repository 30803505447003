import React from 'react';
import styled from 'styled-components';
import { media } from '../../style';
import 'lazysizes';

const VideoWrap = styled.div`
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
`;

const Video = styled.video`
  width: 90%;
  height: auto;
  display: block;
  border-radius: 3px;
  margin: 0 1rem;

  ${media.greaterThan('desktop')`
    margin-left: 5rem;
	`}
`;

const BackgroundImage = styled.img`
  position: absolute;
  max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
  width: 100%;
  cursor: pointer;
  transition: transform 1s ease;

  ${media.greaterThan('desktop')`
    width: 50%;
	`}

  &:hover {
    transform: scale(1.01);
  }
`;

const VideoAutoplay = (props) => {
  const { video, backgroundImage } = props;

  const backgroundImageProps = backgroundImage && {
    src: backgroundImage.url,
    maxWidth: backgroundImage.width,
    className: 'lazyload',
  };

  return (
    <>
      {backgroundImage && <BackgroundImage {...backgroundImageProps} />}
      <VideoWrap>
        <Video autoPlay={true} muted loop playsInline>
          <source src={video.url} type="video/mp4" />
        </Video>
      </VideoWrap>
    </>
  );
};

export default VideoAutoplay;
