import React from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import { Image, Description, CallToAction } from '../../ui';
import { Form } from './';
import { BaseTitle } from '../../ui/Title';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 900px;
  width: 95%;
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadow.promoCard};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 1.2rem;
  margin: 2rem auto;

  ${media.greaterThan('tablet')`
    padding: 5rem 8.5rem 6rem;
    margin: 5rem auto;
  `}

  > * {
    text-align: center;
  }

  img {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const Title = styled(BaseTitle).attrs({ as: 'h2' })`
  margin-bottom: 3rem;
  font-size: ${({ theme }) => theme.fontSize.ll};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.xxl};
  `}
`;

const PromoCard = (props) => {
  const { image, title, content, callToAction, forms } = props;
  const imgProps = image && { image };
  const ctaProps = callToAction && { path: callToAction.url, ...callToAction };
  const formProps = forms && {
    title: forms.title,
    actionLink: forms.actionLink,
    actionType: forms.actionType,
    redirectLink: forms.redirectLink,
    fields: forms.formModularContent,
    direction: forms.direction,
  };
  return (
    <Wrapper>
      {image && <Image {...imgProps} />}
      <Title>{title}</Title>
      <Description>{content}</Description>
      {callToAction && <CallToAction {...ctaProps} />}
      {forms && <Form {...formProps} />}
    </Wrapper>
  );
};

export default PromoCard;
