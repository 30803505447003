import React from 'react';
import styled from 'styled-components';
import { Link, List, ListItem, CollapseSelector, Container } from '../../ui';
import FooterSection from './FooterSection';
import { media } from '../../../style';

const Wrap = styled.footer`
  background-color: ${({ bgColor }) => bgColor};
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: black;
  padding-top: 2rem;

  ${media.greaterThan('tablet')`
    padding: 4rem 0 0;
  `}
`;

const SectionsGrid = styled.nav`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
  margin: 0 auto;
  max-width: 250px;

  ${media.greaterThan('desktop')`
    grid-template-columns: ${({ childrens }) =>
      `1.5fr repeat(${childrens - 1}, 1fr)`};
    max-width: unset;
    text-align: left;
  `}
`;

const LogosWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 5rem 0;
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;
  padding: 2rem 6rem;
`;

const Image = styled.img`
  width: 100%;
`;

const BottomFooter = styled.div`
  background-color: ${({ bg }) => bg};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  padding-top: 4rem;

  ${media.greaterThan('desktop')`
    padding-top: 0;
  `}
`;

const InnerBottomFooter = styled(Container)`
  ${media.greaterThan('desktop')`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}

  ul:nth-child(3) {
    ${media.greaterThan('desktop')`
      display: flex;
      align-items: baseline;
    `}

    li {
      ${media.greaterThan('desktop')`
        padding-left: 1rem;
        padding-right: 1rem;
      `}
    }
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem 0 1rem;
  z-index: 1;

  ${media.greaterThan('desktop')`
    order: 1;
    margin: 0;
  `}
`;

const Icon = styled.img`
  margin: 0 1rem;
`;

const Footer = ({ footer, slug, locale }) => {
  const {
    backgroundColor,
    languages,
    logos,
    sections,
    socialIcons,
    bottomBackgroundColor,
    bottomFooterLinks,
  } = footer;
  const getYear = () => new Date().getFullYear();

  return (
    <Wrap bgColor={backgroundColor && backgroundColor.colorhex.hex}>
      <Container>
        <SectionsGrid childrens={sections.length}>
          {sections.map((section, i) => {
            const footerSectionProps = {
              section,
              slug,
              locale,
              firstSection: i === 0,
            };
            return <FooterSection key={i} {...footerSectionProps} />;
          })}
        </SectionsGrid>
        <LogosWrap>
          {logos &&
            logos.map(({ url }, i) => (
              <ImageWrapper key={i}>
                <Image data-src={url} alt={`footer-image-${i}`} className="lazyload" />
              </ImageWrapper>
            ))}
        </LogosWrap>
      </Container>
      <BottomFooter
        bg={bottomBackgroundColor && bottomBackgroundColor.colorhex.hex}
      >
        <InnerBottomFooter>
          {languages && (
            <CollapseSelector
              locale={locale}
              languages={languages}
              bgColor={
                bottomBackgroundColor && bottomBackgroundColor.colorhex.hex
              }
            />
          )}
          <IconsWrapper>
            {socialIcons &&
              socialIcons.map(({ url, logo }, i) => {
                return (
                  <Link key={i} path={url}>
                    <Icon src={logo.url} />
                  </Link>
                );
              })}
          </IconsWrapper>
          <List>
            {bottomFooterLinks &&
              bottomFooterLinks.map(({ text, url, openInNewTab }, i) => (
                <ListItem footer key={i} textOverflow>
                  <Link
                    path={url}
                    target={openInNewTab ? '_blank' : '_self'}
                    footer
                  >
                    {text}
                  </Link>
                </ListItem>
              ))}
            <ListItem footer textOverflow>
              © Quipu {getYear()}
            </ListItem>
          </List>
        </InnerBottomFooter>
      </BottomFooter>
    </Wrap>
  );
};

export default Footer;
