import React, { useState } from 'react';
import styled from 'styled-components';
import { Video } from '../ui';

const PlayButton = styled.div`
  animation: fadeIn 2s infinite alternate;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTEwcHgiIGhlaWdodD0iMTEwcHgiIHZpZXdCb3g9IjAgMCAxMTAgMTEwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPnBsYXlTVkc8L3RpdGxlPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9InBsYXlTVkciIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIGZpbGwtb3BhY2l0eT0iMC4xNSIgZmlsbD0iI0Y0NEU0OSIgY3g9IjU1IiBjeT0iNTUiIHI9IjU1Ij48L2NpcmNsZT4KICAgICAgICAgICAgPGNpcmNsZSBpZD0iT3ZhbCIgZmlsbD0iI0Y0NEU0OSIgY3g9IjU1IiBjeT0iNTUiIHI9IjQ1Ij48L2NpcmNsZT4KICAgICAgICAgICAgPHBhdGggZD0iTTY4LjU5NCw1My4zNSBDNjkuMTM4MzI3Nyw1My43MjI4Njk2IDY5LjQ2MzczNDUsNTQuMzQwMjA4OCA2OS40NjM3MzQ1LDU1IEM2OS40NjM3MzQ1LDU1LjY1OTc5MTIgNjkuMTM4MzI3Nyw1Ni4yNzcxMzA0IDY4LjU5NCw1Ni42NSBMNTAuMTMxLDY5LjMwOCBDNDguODA0LDcwLjIxOCA0Nyw2OS4yNjggNDcsNjcuNjU4IEw0Nyw0Mi4zNDMgQzQ3LDQwLjczMyA0OC44MDQsMzkuNzgzIDUwLjEzLDQwLjY5MyBMNjguNTk0LDUzLjM1MiBMNjguNTk0LDUzLjM1IFoiIGlkPSJQYXRoIiBmaWxsPSIjRkZGRkZGIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
  background-repeat: no-repeat;
  height: 15rem;
  position: absolute;
  width: 15rem;
  z-index: 50;
  @keyframes fadeIn {
    from {
      opacity: 0.3;
    }
  }
`;

const Button = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  position: absolute;
  width: 6rem;
  cursor: pointer;
  z-index: 50;
`;

const AnimatedPlayButton = (props) => {
  const { url, i } = props;
  const [showVideo, setShowVideo] = useState(false)

  const toggleVideo = () => {
    setShowVideo(false)
  }

  const onClickHandler = () => {
    setShowVideo(true)
  }

  return (
    <Button>
      <PlayButton onClick={ onClickHandler } />
      { showVideo && <Video url={ url } onClick={ toggleVideo } i={ i } /> }
    </Button>
  );
};

export default AnimatedPlayButton;
