import React from 'react';
import styled from 'styled-components';
import { Link } from '../../../ui';
import { Wrapper, Text, Author } from './components';

const LogoWrap = styled.div`
  width: 100px;
  display: inline-block;
  transition: transform .35s ease;

  &:hover {
    transform: scale(1.05);
  }
`

const Logo = styled.img`
  width: 100%;
`

const StyledAuthor = styled(Author)`
  margin-top: 3rem;
  margin-left: .2rem;
`

const StyledText = styled(Text)`
  margin-left: .2rem;
  margin-top: 2rem;
`

const SassReview = props => {
  const { profileImage, author, jobPosition, url, review } = props;

  return (
    <Wrapper maxWidth="500px" height="240px">
      {profileImage && (
        <Link path={url} target="_blank" rel="nofollow">
          <LogoWrap>
            <Logo src={profileImage.url} />
          </LogoWrap>
        </Link>
      )}
      <StyledText lineHeight="medium" fontSize="sm">
        {review}
      </StyledText>
      <StyledAuthor fontSize="s" color="gray" fontWeight="medium">
        {author}
      </StyledAuthor>
    </Wrapper>
  );
};

export default SassReview;
