import React from 'react'
import styled from 'styled-components'
import { media } from '../../../style'
import CancelSVG from '../../../assets/svg/cancel.svg'
import CheckSVG from '../../../assets/svg/check.svg'
import { CallToAction } from '../../ui'

const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  overflow-x: scroll;
`

const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
  position: relative;
`

const Tbody = styled.tbody`
  text-align: left;
`

const Tr = styled.tr`
  display: ${ ({ withButtons }) => withButtons ? 'none' : 'inherit' };
  position: ${ ({ iva }) => iva ? 'absolute' : 'relative' };

  > td {
    padding: ${ ({ withButtons }) => withButtons && '1rem' };
    ${ media.greaterThan('tablet')`
      padding: 3rem;
      > a {
        line-height: 2rem;
        padding-left: 0;
        padding-right: 0;
        display: block;
      }
    `}
  }

  ${ media.greaterThan('tablet')`
    display: table-row;
  `}
`

const Td = styled.td`
  font-size: ${({ theme, iva, rowTitle, columnTitle }) =>
    theme.fontSize[iva ? 's' : rowTitle ? 'l' : columnTitle ? 'l' : 'sm']};
  font-weight: ${({ theme, rowTitle, columnTitle }) =>
    theme.fontWeight[rowTitle ? 'semibold' : columnTitle ? 'medium' : 'regular']};
  padding: ${({ rowTitle }) => (rowTitle ? '3rem 0 1rem' : '1rem')};
  color: ${({ theme, rowTitle }) =>
    theme.colors[rowTitle ? 'darkGray' : 'darkGray']};
  width: ${({ columnTitle }) => columnTitle && '10px'};
  word-wrap: ${({ columnTitle }) => !columnTitle && 'break-word'};
  line-height: ${({ columnTitle }) => (columnTitle ? '1.5' : '3rem')};

  ${media.greaterThan('tablet')`
    font-size: ${({ theme, iva, rowTitle, columnTitle }) =>
      theme.fontSize[iva ? 's' : rowTitle ? 'l' : columnTitle ? 'xl' : 'sm']};
  `}

  ${media.greaterThan('mobile')`
    width: auto;
  `}

  &:not(:first-child) {
    text-align: center;
  }

  &:first-child {
    padding-left: ${({ rowTitle }) => !rowTitle && '2rem'};
  }
`;

const Table = props => {
  const { data: { dataTable, cta } } = props;

  return (
    <Wrapper>
      <StyledTable>
        <Tbody>
          {/*
           Since we did "the big upgrade", dataTable was a String instead of
           an Object, so we needed a conversion.
          */}
          { JSON.parse(dataTable).map(({ row }, i) => {
            const { cell, rowTitle, columnTitle } = row
            const tdProps = {
              rowTitle: rowTitle === 'true',
              columnTitle: columnTitle === 'true'
            }

            return (
              <Tr key={ i } iva={ i === dataTable.length - 1 }>
                { cell.map((text, index) =>
                  <Td key={ index } iva={ i === dataTable.length - 1 } { ...tdProps } >
                    { text !== "true" && text !== "false" ? text : text === "true" ? <CheckSVG /> : <CancelSVG />}
                  </Td> )
                }
              </Tr>
            )
          }) }
            <Tr withButtons>
              <Td></Td>
              { cta.map((button, i) => <Td key={ i }><CallToAction path={ button.url } { ...button } /></Td> ) }
            </Tr>
        </Tbody>
      </StyledTable>
    </Wrapper>
  )
}

export default Table
