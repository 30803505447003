import React from 'react'
import styled from 'styled-components'
import { media } from '../../../style'
import { CallToAction } from '../../ui'


const Wrapper = styled.div`
  text-align: center;
`

const CardsWrapper = styled.div`
  overflow-x: visible;
`

const Card = styled.div`
  background-color: ${ ({ theme }) => theme.colors.white };
  max-width: 932px;
  margin: 0 auto 12rem;
  padding: 3rem 15rem 5rem;
  border-radius: 6px;
  box-shadow: ${ ({ theme }) => theme.shadow.pricingCard };
  text-align: center;
  flex-shrink: 0;
  width: 100%;
  position: relative;

  ${ media.lessThan('desktop')`
    width: auto;
    margin: 0rem 2.8rem 10rem;
    padding: 3rem 6rem 4rem;
  `}
  ${ media.lessThan('mobile')`
    position: relative;
    top: 0;
    left: calc(50% - 150px);
    width: 300px;
    margin: 3rem 0 8rem;
    padding: 3rem 3rem;
` }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: ${({ theme }) => theme.fontSize.ll};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-top: 1.5rem;

  ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.xll};
  `}
`;

const UpperImage = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  top: -30px;
  left: calc(50% - 30px);
`

const Image = styled.img`
  margin: 3rem 0;
  max-width: 100%;
`

const DescriptionText = styled.p`
  font-size: ${ ({ theme }) => theme.fontSize.s };
  font-weight: ${ ({ theme }) => theme.fontWeight.medium };
  line-height: 2.5rem;
  height: auto;
  margin-bottom: 3rem;
`

const FullWidthCard = ({ data }) => (
  <Wrapper>
    <CardsWrapper>
      { data.map((card, i) => {
        const { title, image, description, upperImage, calltoaction } = card
        return (
          <Card key={ i }>
            { upperImage && <UpperImage data-src={ upperImage.url } alt={ upperImage.alt } className='lazyload' /> }
            <Title>{ title }</Title>
            { image && <Image data-src={ image.url } alt={ image.alt } className='lazyload' /> }
            <DescriptionText>{ description }</DescriptionText>
            <CallToAction path={ calltoaction.url } { ...calltoaction } block={ false } large />
          </Card>
        )
      }) }
    </CardsWrapper>
  </Wrapper>
)

export default FullWidthCard
