import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MinusSVG from '../../../assets/svg/minus.svg';
import PlusSVG from '../../../assets/svg/plus.svg';
import { media } from '../../../style';

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.greaterThan('tablet')`
  flex-flow: row;
  margin: 0 6rem;
`}
`;

const ColumnOne = styled.div`
  ${media.greaterThan('tablet')`
display: flex;
flex-direction: column;
max-width: 45%;
margin: 0;
`}
`;

const ColumnTwo = styled.div`
  ${media.greaterThan('tablet')`
display: flex;
flex-direction: column;
max-width: 45%;
 margin-left: 8rem;
`}
`;

const Wrapper = styled.div`
  margin: 1rem 0;
  text-align: left;
  ${media.greaterThan('tablet')`
  margin: 1.5rem 0;
`}
`;

const Title = styled.h3`
  ${({ isExpanded, theme }) => ` 
color: ${isExpanded ? theme.colors.darkGreen : theme.colors.black};
font-size: ${theme.fontSize.m};
font-weight: ${isExpanded ? theme.fontWeight.medium : theme.fontWeight.medium};
padding: 0.5rem 0;
`}
  cursor: ${({ clickable }) => !clickable && 'pointer'};
  position: relative;
`;

const IconClose = styled.span`
  ${({ isExpanded, theme }) => ` 
  svg path {
    fill: ${isExpanded ? theme.colors.darkGreen : theme.colors.black};
  }
`}
  position: absolute;
  top: -3px;
  left: -25px;
`;

const IconOpen = styled.span`
  ${({ isExpanded, theme }) => ` 
  svg path {
    fill: ${isExpanded ? theme.colors.darkGreen : theme.colors.black};
  }
`}
  font-size: 2rem;
  position: absolute;
  top: 4px;
  left: -25px;
`;

const Content = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.darkGray};
    font-size: ${theme.fontSize.m};
    line-height: ${theme.lineHeight.medium};
  `}
  ${({ isExpanded }) => `
    max-height: ${isExpanded ? '100%' : '0'};
    opacity: ${isExpanded ? '1' : '0'};
    display: ${isExpanded ? '' : 'none'};
  `}
  margin: 2rem 0 0;
  overflow: visible;
  transition: max-height 0.25s ease, opacity 0.35s ease;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.teal};
  }

  strong {
    font-weight: 600;
  }

  p {
    line-height: ${({ theme }) => theme.lineHeight.medium};
    padding: 0.5rem 0;
  }
`;

const FaqItem = ({ title, content }) => {
  const isDesktop = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth > 1200 ? true : false;
    }
  };
  const [expand, setExpand] = useState(false);
  const [desktop, setDesktop] = useState(isDesktop());
  const handleClick = () => {
    setExpand(!expand);
  };

  useEffect(() => {
    const eventHandler = () => setDesktop(isDesktop());
    window.addEventListener('resize', eventHandler);

    return () => window.removeEventListener('resize', eventHandler);
  });

  return (
    <Wrapper>
      <Title onClick={handleClick} clickable={desktop} isExpanded={expand}>
        {expand ? (
          <IconClose isExpanded={expand}>
            <MinusSVG />
          </IconClose>
        ) : (
          <IconOpen isExpanded={expand}>
            <PlusSVG />
          </IconOpen>
        )}
        {title}
      </Title>
      <Content isExpanded={expand}>
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </Content>
    </Wrapper>
  );
};

const Faqs = (props) => {
  const { data } = props;

  const columnOne = [];
  const columnTwo = [];

  return (
    <ContainerWrapper>
      {data.map((faq, i) => {
        if (i % 2 === 0) {
          columnOne.push(faq);
        } else {
          columnTwo.push(faq);
        }
      })}
      <ColumnOne>
        {columnOne.map((faq, index) => {
          return <FaqItem key={index} {...faq} />;
        })}
      </ColumnOne>
      <ColumnTwo>
        {columnTwo.map((faq, index) => {
          return <FaqItem key={index} {...faq} />;
        })}
      </ColumnTwo>
    </ContainerWrapper>
  );
};

export default Faqs;
