import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../../../../style';
import { Link } from '../../../ui';
import { Wrapper, ContentWrap, JobPosition, Author, Text } from './components';
import PlaySVG from '../../../../assets/svg/play_video_white.svg';
import { FormattedText } from '../';

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  padding: 0;
  ${media.greaterThan('tablet')`
  flex-direction: row;
	`}
  ${media.greaterThan('desktop')`
    height: 280px;
  `}
`;

const VideoWrap = styled.div`
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-size: contain;
  position: relative;
  cursor: pointer;
  ${media.greaterThan('tablet')`
    flex-basis: 35%;
    margin-left: 5px;
	`}
  ${media.lessThan('desktop')`
    height:200px;
  `}
`;

const StyledPlaySVG = styled(PlaySVG)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('desktop')`
    width: 40px;
    height: 40px;  `}
`;

const StyledContentWrap = styled(ContentWrap)`
  ${media.greaterThan('tablet')`
    flex-basis: 65%;
	`}
  ${media.lessThan('desktop')`
    padding-right: 2rem;
  `}
`;

const StyledText = styled(Text)`
  ${media.greaterThan('desktop')`
    font-size: ${({ theme }) => theme.fontSize.m};
  `}
`;

const PartnersReview = (props) => {
  const {
    profileImage,
    author,
    jobPosition,
    review,
    video,
    onClick,
    url,
    onMouseEnter,
    onMouseLeave,
  } = props;

  return (
    <StyledWrapper
      maxWidth="1100px"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <VideoWrap
        onClick={video ? () => onClick(video) : null}
        bgImage={profileImage && profileImage.url}
      >
        <StyledPlaySVG />
      </VideoWrap>
      <StyledContentWrap padding="3rem 8rem 3rem 3rem">
        <Author marginBottom="1.5rem" fontSize="m" fontWeight="bold">
          {author}
        </Author>
        <Link path={url} target="_blank" rel="nofollow">
          <JobPosition fontSize="sm">{jobPosition}</JobPosition>
        </Link>

        <StyledText color="darkGray" fontSize="s" lineHeight="double">
          {review}
        </StyledText>
      </StyledContentWrap>
    </StyledWrapper>
  );
};

export default PartnersReview;
