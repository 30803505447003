import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Navigation, Footer, SEO, CookieBox } from './PageComps';
import BookkeeperAreaBanner from './PageComps/Navigation/BookkeeperAreaBanner';
import TopBar from './PageComps/Navigation/TopBar';

import Section from './Section';
import { Video, Search } from './ui';
import { GlobalStyle, theme } from '../style';
import isIncluded from '../helpers/isIncluded';

const Main = styled.main`
  margin-top: ${({ noMarginTop }) => !noMarginTop && '9rem'};
`;

const Header = styled.main`
  position: fixed;
  width: 100%;
  margin: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
  margin-bottom: 4rem;
`;

const Page = ({ pageContext }) => {
  const { locale, slug, originalId, alternates, data, indexedPages } = pageContext;
  const {
    title,
    sections,
    footer,
    navigation,
    seoMetaTags,
    faviconMeta,
    blogFeed,
    cookieBox,
    video,
    topbar,
    metaIndexFollow,
    search,
    bookkeeperAreaOnly,
  } = data;
  const seoProps = {
    locale,
    slug,
    originalId,
    alternates,
    seoMetaTags,
    faviconMeta,
    title,
    metaIndexFollow,
  };
  const navProps = { locale, slug, navigation };
  const footerProps = { footer, slug, locale };
  const cookieProps = { cookieBox, locale };

  const [showVideo, setShowVideo] = useState(false);
  const toggleVideo = () => setShowVideo(!showVideo);
  const videoProps = { ...video, onClick: toggleVideo, showVideo };
  const topBarProps = { ...topbar };

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <SEO {...seoProps} />
        {bookkeeperAreaOnly && <BookkeeperAreaBanner />}
        <CookieBox {...cookieProps} />
        <Header>
        {topbar && <TopBar {...topBarProps} />}
        {navigation && <Navigation {...navProps} />}
        </Header>

        <Main noMarginTop={!navigation}>
          {video && showVideo && <Video {...videoProps} />}
          {search && <Search pages={ indexedPages } />}
          {sections &&
            sections.map((section, i) => {
              const sectionProps = {
                blogFeed: isIncluded(section.title, 'blogfeed') && blogFeed,
                locale,
                slug,
                onClick: video && toggleVideo,
                ...section,
              };
              return <Section key={i} {...sectionProps} />;
            })}
        </Main>
        {footer && <Footer {...footerProps} />}
      </>
    </ThemeProvider>
  );
};

export default Page;
