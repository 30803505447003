import React from 'react';
import styled from 'styled-components';
import { media } from '../../style';
import { Link } from './';
import { AnimatedPlayButton } from './';
import 'lazysizes';

const isMarginAuto = (margin) => (margin === 'default-padding' ? true : false);
const isStoreLogo = (url) =>
	url.includes('googleplay') || url.includes('appstore');
const isFeaturesImg = (url) => url.includes('envelope');

const getMargin = ({
	isLegalPage,
	isRegister,
	hideOnMobile,
	url,
	margin,
	small,
}) => {
	let val = 'margin: ';
	if (isStoreLogo(url)) {
		return val.concat('.6rem 2rem;');
	} else if (isLegalPage) {
		return val.concat('2rem 0;');
	} else if (isRegister && !hideOnMobile) {
		return val.concat('0 auto 4rem;');
	} else if (isMarginAuto(margin) || small) {
		return val.concat('2rem auto;');
	}
	return val.concat('0;');
};

const ImgWrap = styled.div`
	position: relative;
	${getMargin}
	max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
	width: ${({ small, isRegister, hideOnMobile }) =>
		small ? '70px' : isRegister && !hideOnMobile ? '150px' : ''};
	${({ isLegalPage }) =>
		isLegalPage
			? `
		max-width: 100px; margin-left: auto; margin-right: auto;
	`
			: ''}

	${media.greaterThan('tablet')`
		${({ isLegalPage }) =>
			isLegalPage && `margin-left: unset; margin-right: unset;`};
	`}

	${media.greaterThan('desktop')`
		display: ${({ url }) => isFeaturesImg(url) && 'none'};
		${({ isLegalPage }) =>
			isLegalPage &&
			`
			margin-left: unset; margin-right: unset;
		`};
	`}
`;

const Img = styled.img`
	width: 100%;
	height: auto;
	display: block;
`;

const SecondImage = styled.img`
	position: absolute;
	max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
	width: 80%;
	top: 15%;
	right: 5rem;
	cursor: pointer;
	transition: transform 1s ease;

	${media.greaterThan('desktop')`
		right: 10rem;
	`}

	&:hover {
		transform: scale(1.01);
	}
`;

const AnimatedPlayButtonWrapper = styled.div`
	position: absolute;
	width: 80%;
	top: 35%;
	right: 25%;
	cursor: pointer;
	${media.greaterThan('tablet')`
    top: 35%;
    left: 40%;
	`}
	${media.greaterThan('desktop')`
    top: 40%;
    left: 40%;
	`}
`;

const Image = (props) => {
	const {
		image,
		secondImage,
		alt,
		caption,
		isLegalPage,
		isRegister,
		hideOnMobile,
		paddingOptions,
		regularLink,
		small,
		onClick,
		videoLink,
		animatedPlayButton,
	} = props;
	const imgWrapProps = image && {
		isLegalPage,
		isRegister,
		hideOnMobile,
		margin: paddingOptions,
		url: image.url,
		link: regularLink,
		small,
	};
	const imgProps = image && {
		['data-src']: image.url,
		alt,
		onClick,
		className: 'lazyload',
	};
	const secondImageProps = secondImage && {
		src: secondImage.url,
		maxWidth: secondImage.width,
		onClick,
		className: 'lazyload',
	};

	const animatedPlayButtonProps = animatedPlayButton && {
		url: videoLink,
		onClick,
		className: 'lazyload',
	};
	return !regularLink ? (
		<ImgWrap {...imgWrapProps}>
			{secondImage && <SecondImage {...secondImageProps} />}
			{animatedPlayButton && (
				<AnimatedPlayButtonWrapper>
					<AnimatedPlayButton {...animatedPlayButtonProps} />
				</AnimatedPlayButtonWrapper>
			)}
			<Img {...imgProps} />
			{caption && (
				<Link path={caption.url} target='_blank'>
					{caption.text}
				</Link>
			)}
		</ImgWrap>
	) : (
		<a href={regularLink} target='_blank' rel='noopener noreferrer'>
			<ImgWrap {...imgWrapProps}>
				{animatedPlayButton && (
					<AnimatedPlayButton {...animatedPlayButtonProps} />
				)}
				{secondImage && <SecondImage {...secondImageProps} />}
				<Img {...imgProps} />
				{caption && (
					<Link path={caption.url} target='_blank'>
						{caption.text}
					</Link>
				)}
			</ImgWrap>
		</a>
	);
};

export default Image;
