import React from 'react';
import styled from 'styled-components';
import QuotesSVG from '../../../../assets/svg/double-quotes.svg';
import { Wrapper, ContentWrap, Author, JobPosition, Text } from './components';

const Quotes = styled.span`
  position: relative;
  top: 1rem;
  left: 1rem;
`;

const ImgWrapper = styled.div`
  height: 110px;
  width: 110px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-position: center;
  background-size: contain;
  border: 4px solid ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadow.card};
`;

const Image = styled.img`
  width: 100%;
`;

const WorkerReview = (props) => {
  const { profileImage, author, jobPosition, review } = props;

  return (
    <Wrapper maxWidth="450" height="350px">
      <ImgWrapper bgImage={profileImage.url} />
      <ContentWrap margin="2rem 0">
        <Author marginBottom="1rem" fontWeight="bold">
          {author}
        </Author>
        <JobPosition color="mediumGray" fontSize="sm">
          {jobPosition}
        </JobPosition>
        <Text fontSize="sm" color="darkGray" lineHeight="double">
          {review}
        </Text>
      </ContentWrap>
    </Wrapper>
  );
};

export default WorkerReview;
