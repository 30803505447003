import React from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import { BaseTitle } from '../../ui/Title';
import { Link } from '../../ui';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection ? 'column' : 'row')};
  align-items: ${({ flexDirection }) =>
    flexDirection ? 'center' : 'flex-start'};
  text-align: ${({ flexDirection }) => (flexDirection ? 'center' : 'left')};
  justify-content: center;
  max-width: 400px;
  margin: 1.5rem auto;

  > div:first-child {
    ${({ flexDirection }) => (flexDirection ? ' margin-right: 0;' : '')}
    align-items: ${({ flexDirection }) =>
      flexDirection ? 'center' : 'flex-start'};
  }

  ${media.greaterThan('tablet')`
    justify-content: flex-start;
    margin: 1.5rem 0;
  `}
`;

const IconWrapper = styled.div`
  margin-right: 2.5rem;
  margin-bottom: 3rem;
  flex-shrink: 0;
  height: 120px;
  display: flex;
  justify-content: center;
`;

const Icon = styled.img`
  padding-top: 0.5rem;
  width: 100%;
`;

const Title = styled(BaseTitle).attrs({ as: 'h3' })`
  font-size: ${({ theme }) => theme.fontSize.m};
  margin-bottom: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.l};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `}
`;

const ContentWrapper = styled.div``;

const Content = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  color: ${({ theme }) => theme.colors.darkGray};

  ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.m};
    padding: ${({ flexDirection }) => flexDirection && '0 3rem'};
  `}
`;

const FeaturedContent = (props) => {
  const { data } = props;
  return (
    <>
      {data.map((feature, i) => {
        const { icon, title, content, direction, link } = feature;
        const linkProps = link && {
          path: link.url,
          children: link.text,
          featured: true,
          icon: link.icon.url,
        };

        return (
          <Wrapper key={i} flexDirection={direction}>
            <IconWrapper>
              <Icon src={icon.url} />
            </IconWrapper>
            <ContentWrapper>
              <Title itemTitle>{title}</Title>
              <Content flexDirection={direction}>{content}</Content>
              {linkProps && <Link {...linkProps} />}
            </ContentWrapper>
          </Wrapper>
        );
      })}
    </>
  );
};

export default FeaturedContent;
