import React from 'react';
import styled from 'styled-components';
import { media } from '../../../style';
import { BaseTitle } from '../../ui/Title';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  max-width: 900px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  text-align: left;
  transition: transform 1s ease;
  padding: 1.5rem 1.2rem;
  margin: 0 auto 2rem;

  &:first-of-type {
    margin-top: 3rem;
  }

  &:last-of-type {
    margin-bottom: 3rem;
  }

  &:hover {
    transform: scale(1.01);
  }

  ${media.greaterThan('tablet')`
    max-height: 180px;
    padding: 2rem 3.5rem 2rem;
    margin: 0 auto 2rem;

    &:first-of-type {
      margin-top:5rem;
    }

    &:last-of-type {
      margin-bottom:5rem;
    }
  `}
`;

const CardWrapper = styled.a`
  text-decoration: none;

  ${media.greaterThan('tablet')`
    display: flex;
    flex-direction: row;
  `}
`;

const Title = styled(BaseTitle).attrs({ as: 'h2' })`
  margin: 1.5rem 0;
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${media.greaterThan('tablet')`
    font-size: ${({ theme }) => theme.fontSize.l};
  `}
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme, color }) => (color ? color : theme.colors.mediumGray)};
  line-height: ${({ theme }) => theme.lineHeight.medium};
  text-align: initial;
  margin-bottom: 2rem;
  display: inline-block;

  ${media.greaterThan('tablet')`
      font-size: ${({ theme }) => theme.fontSize.m};
  `}
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;

  ${media.greaterThan('tablet')`
    width:120px;
    height:120px;
    margin-right: 2.5rem;
  `}
`;

const Icon = styled.img`
  width: 100%;
  max-height: 30px;
  max-width: 30px;
  display: block;
  margin: auto;

  ${media.greaterThan('tablet')`
    max-width: 60px;
    max-height: 60px;
  `}
`;

const KnowledgeArticle = (props) => {
  const { data } = props;
  return (
    <Wrapper>
      <CardWrapper href={data.redirect.url}>
        <IconWrapper>
          <Icon src={data.icon.url} />
        </IconWrapper>
        <div>
          <Title>{data.title}</Title>
          <Description> {data.description}</Description>
        </div>
      </CardWrapper>
    </Wrapper>
  );
};

export default KnowledgeArticle;
